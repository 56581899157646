export const parsePageVersion = (position: number): string | null => {
  if (position < 0) {
    return null
  }

  let characters = ''

  if (position <= 25) {
    characters = String.fromCharCode('A'.charCodeAt(0) + position)
  } else {
    const letterIndex = Math.floor((position - 26) / 9)
    const numberIndex = ((position - 26) % 9) + 1
    characters = String.fromCharCode('A'.charCodeAt(0) + letterIndex) + numberIndex
  }

  return characters
}
