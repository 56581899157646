import { CustomerAddress } from '@libs/client/order/services'
import getLogRocket from '@ui/analytics/logRocket'
import { getQueryString, isEmpty, getQueryParams } from '@ui/helpers'
import useAxios from '../useAxios'
import { getCustomerRequest, getTicketType, updateTrackingInfo } from './helper'

export enum TicketType {
  FORGOT_ORDER = 'FORGOT_ORDER',
  WHERE_ORDER = 'WHERE_ORDER',
  MODIFY_ORDER = 'MODIFY_ORDER',
  CLAIM_ORDER = 'CLAIM_ORDER'
}

export enum LevelType {
  INQUIRY = 'INQUIRY',
  CHANGE_REQUEST = 'CHANGE_REQUEST',
  COMPLAINT = 'COMPLAINT'
}

export enum TicketState {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  RESOLVED = 'RESOLVED',
  SUBMITTED = 'SUBMITTED'
}

export enum TicketAction {
  ORDER_STATUS = 'ORDER_STATUS',
  QUESTION_ORDER_LOST = 'QUESTION_ORDER_LOST',
  QUESTION_ORDER_LATE = 'QUESTION_ORDER_LATE',
  UPDATE_SHIPPING = 'UPDATE_SHIPPING',
  CANCEL_ORDER = 'CANCEL_ORDER',
  CHANGE_VARIANT = 'CHANGE_VARIANT',
  REPLACE_ORDER = 'REPLACE_ORDER',
  KEEP_AND_GET_REFUND = 'KEEP_AND_GET_REFUND',
  RETURN_ORDER = 'RETURN_ORDER'
}

export enum FreshdeskTicketStatus {
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  CLOSED = 'CLOSED'
}

// type PagingParams = {
//   page?: number
//   size?: number
// }

type PagingParamsEmail = {
  page?: number
  size?: number
  email?: string
}

interface IOrderContainer {
  relative: (params?: PagingParamsEmail) => Promise<Types.OrderShopHelp[]>
  detailById: (id: string) => Promise<Types.OrderShopHelp>
  detailByInfo: (params: Types.LoginModel) => Promise<Types.OrderShopHelp>
  detailByInfoAuth: (params: Types.LoginModel) => Promise<Types.OrderShopHelp>
  tickets: (params: Partial<Types.TicketRequest>) => Promise<Types.ShophelpRequest>
  updateTickets: (params: {
    id: string
    ticket_state: TicketState
    description: string
  }) => Promise<Types.ShophelpRequest>
  findOrder: (params: Types.FindOrderRequest) => Promise<Types.ShophelpRequest>
  getShipping: (id: string) => Promise<CustomerAddress>
}

const orderContainer = {} as IOrderContainer

export function useOrderService(basePath = ''): IOrderContainer {
  if (!isEmpty(orderContainer)) {
    return orderContainer
  }
  const { logRocket } = getLogRocket()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { instance } = useAxios<Types.OrderShopHelp>(basePath)

  orderContainer.detailById = (id: string) => {
    return instance.get(`/order/${id}/detail`).then((order: any) => {
      return {
        ...order,
        tracking_infos: order.tracking_infos?.map(updateTrackingInfo) || []
      }
    })
  }

  orderContainer.detailByInfo = (params: Types.LoginModel) => {
    params.email = params.email?.trim() || ''
    params.order = params.order?.trim() || ''
    const order_name = params.order?.includes('#') ? params.order : `#${params.order}`
    return instance
      .get(`/order/p10n/detail${getQueryString({ email: params.email, order_name })}`)
      .then((order: any) => {
        if (order) {
          return {
            ...order,
            tracking_infos: order.tracking_infos!.map(updateTrackingInfo)
          }
        } else {
          //console.error('Server return no response when request get detail order')
          throw {
            status: 404
          }
        }
      })
  }

  orderContainer.detailByInfoAuth = (params: Types.LoginModel) => {
    params.email = params.email?.trim() || ''
    params.order = params.order?.trim() || ''
    const order_name = params.order?.includes('#') ? params.order : `#${params.order}`
    logRocket?.identifyContact({
      email: params.email,
      phone: '',
      firstName: params.email,
      lastName: params.order,
      code: params.order,
      order: params.order
    })
    return instance
      .get(`/order/detail${getQueryString({ email: params.email, order_name })}`)
      .then((order: any) => {
        if (order) {
          return {
            ...order,
            tracking_infos: order.tracking_infos!.map(updateTrackingInfo)
          }
        } else {
          //console.error('Server return no response when request get detail order by auth')
          throw {
            status: 404
          }
        }
      })
  }

  orderContainer.tickets = (params: Partial<Types.TicketRequest>) => {
    const url = '/order/tickets'
    const data = new FormData()
    if (isEmpty(params)) {
      throw new Error('Not enough data to request a support ticket!')
    }
    params.customer_request = getCustomerRequest(params)
    params.ticket_type = getTicketType(params as Types.TicketRequest)
    params.source = 'PORTAL'
    Object.entries(params).forEach(([key, value]: [string, any]) => {
      if (key === 'address') {
        for (const field in value) {
          data.append(`address.${field}`, value[field].trim())
        }
      } else if (key === 'attachments') {
        for (const file of value) {
          data.append('attachments', file)
        }
      } else if (key === 'item_ids') {
        for (const id of value) {
          data.append('item_ids', id)
        }
      } else if (key === 'trouble') {
        //issue
        for (const item of value.issue) {
          data.append('trouble.issues', item)
        }
        //title
        data.append('trouble.title', value.title)
        //trouble_id
        data.append('trouble.trouble_id', value.trouble_id)
        //value
        data.append('trouble.value', value.value)
      } else {
        if (typeof value === 'string') {
          data.append(key, value ? value.trim() : '')
        } else {
          data.append(key, value)
        }
      }
    })

    return instance
      .post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res: any) => {
        if (res) {
          return res
        } else {
          console.warn(
            `[${params?.order_name}] [${
              window.location.href || ''
            }] Server return no response when request create a support ticket`
          )
          throw {
            status: 404
          }
        }
      })
  }

  orderContainer.updateTickets = (params: {
    id: string
    ticket_state: TicketState
    description: string
  }) => {
    const url = `/order/tickets/${params.id}`
    const data = new FormData()

    Object.entries(params).forEach(([key, value]: [string, any]) => {
      data.append(key, value.trim())
    })

    return instance
      .put(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((data: any) => {
        if (data) {
          return data
        } else {
          console.warn(
            `Server return no response when request update a support ticket ${params?.id}`
          )
          throw {
            status: 404
          }
        }
      })
  }

  orderContainer.findOrder = (params: Types.FindOrderRequest) => {
    const url = '/order/find-order'
    params.source = 'PORTAL'
    return instance.post(url, params)
  }

  orderContainer.relative = (params: PagingParamsEmail = { page: 1, size: 100 }) => {
    return instance.get(`/order/relative${getQueryParams(params)}`).then((res: any) => {
      //if (!res) console.error('Server return no response when request get list order')
      return res.map((order: Types.OrderShopHelp) => {
        return {
          ...order
        }
      })
    })
  }
  orderContainer.getShipping = (id: string) => {
    return instance.get(`/order/shipping?id=${id}`).then((res: any) => {
      const shipping = res?.shipping as Types.AddressShopHelp
      return {
        email: shipping?.email,
        phone: shipping?.phone,
        last_name: shipping?.last_name,
        first_name: shipping?.first_name,
        address1: shipping?.address1,
        address2: shipping?.address2,
        postal_code: shipping?.zip,
        city: shipping?.city,
        state: shipping?.province,
        country: shipping?.country,
        country_code: shipping?.country_code
      } as CustomerAddress
    })
  }

  return orderContainer
}
