import { getCurrency } from '@ui/helpers'

export function getCustomerRequest(ticket: Partial<Types.TicketRequest>): string {
  const ticketAction = ticket.action
  const ticketType = ticket.ticket_type

  if (ticketType === 'WHERE_ORDER' && ticketAction === 'QUESTION_ORDER_LOST') {
    return 'REPORT_MISSING'
  }

  if (ticketType === 'WHERE_ORDER' && ticketAction === 'QUESTION_ORDER_LATE') {
    return 'REPORT_LATE'
  }

  if (ticketType === 'WHERE_ORDER' && ticketAction === 'ORDER_STATUS') {
    return 'TRACK_ORDER'
  }

  if (ticketType === 'CLAIM_ORDER' && ticketAction === 'REPLACE_ORDER') {
    return 'REPLACE'
  }

  if (ticketType === 'CLAIM_ORDER' && ticketAction === 'KEEP_AND_GET_REFUND') {
    return 'PARTIAL_REFUND'
  }

  if (ticketType === 'CLAIM_ORDER' && ticketAction === 'RETURN_ORDER') {
    return 'RETURN'
  }

  return ticketAction || ''
}

export function getTicketType(ticket: Types.TicketRequest): string {
  const ticketType = ticket.ticket_type

  if (ticketType === 'MODIFY_ORDER' && ticket.action === 'UPDATE_SHIPPING') {
    return 'UPDATE_SHIPPING'
  }

  if (ticketType === 'MODIFY_ORDER' && ticket.action === 'CANCEL_ORDER') {
    return 'CANCEL_ORDER'
  }

  return ticketType || ''
}

export function createFormDataBody(body: Types.PlainObject) {
  const formData = new FormData()
  Object.entries(body).forEach(([key, value]: [key: string, value: any]) => {
    if (Object.prototype.toString.call(value) === '[object Array]') {
      for (const item of value) {
        formData.append(key, item as any)
      }
    } else {
      formData.append(key, value)
    }
  })
  return formData
}

export const getPoStatus = (finacialInfo: Types.PaymentInfo) => {
  return finacialInfo.refund_amount &&
    finacialInfo.refund_amount > 0 &&
    finacialInfo.refund_amount < finacialInfo.amount!
    ? 'Partially Refund'
    : finacialInfo.refund_amount === finacialInfo.amount
    ? 'Refunded'
    : ''
}

export function getCountStatus(trackingInfo: Types.TrackingInfo[]) {
  const CountStatus = {
    shipped: 0,
    paid: 0,
    delivered: 0,
    processing: 0,
    other: 0,
    canceled: 0
  }
  for (const tracking of trackingInfo) {
    const status = tracking.status
    switch (status) {
      case 'PAID':
        CountStatus.paid++
        break
      case 'UNFULFILL':
        CountStatus.paid++
        break
      case 'PROCESSING':
        CountStatus.processing++
        break
      case 'DELIVERED':
        CountStatus.delivered++
        break
      case 'PARTIALLY_DELIVERED':
        CountStatus.delivered++
        break
      case 'SHIPPED':
        CountStatus.shipped++
        break
      case 'CANCEL':
        CountStatus.canceled++
        break
      default:
        CountStatus.other++
        break
    }
  }
  return CountStatus
}

export function convertTrackingPOStatus(order: Types.OrderShopHelp): string {
  const trackingInfo = order?.tracking_infos as Types.TrackingInfo[]
  if (!trackingInfo || !trackingInfo.length) {
    return ''
  }
  const CountStatus = getCountStatus(trackingInfo)
  const TRACKING_PO_STT = {
    ALL_PAID: 'Your order has been received!',
    ALL_PROCESSING: 'We are processing your order!',
    ALL_SHIPPED: 'Your order has been shipped!',
    PART_SHIPPED: 'Part of your order has been shipped!',
    ALL_DELIVERED: 'Your order has been delivered!',
    PART_DEVLIRED: 'Part of your order has been delivered!',
    DEFAULT: ''
  }
  if (
    CountStatus.shipped === trackingInfo.length - CountStatus.canceled &&
    CountStatus.shipped > 0
  ) {
    return TRACKING_PO_STT.ALL_SHIPPED
  }
  if (CountStatus.paid === trackingInfo.length - CountStatus.canceled && CountStatus.paid > 0) {
    return TRACKING_PO_STT.ALL_PAID
  }
  if (
    CountStatus.delivered === trackingInfo.length - CountStatus.canceled &&
    CountStatus.delivered > 0
  ) {
    return TRACKING_PO_STT.ALL_DELIVERED
  }
  if (
    CountStatus.processing === trackingInfo.length - CountStatus.canceled &&
    CountStatus.processing > 0
  ) {
    return TRACKING_PO_STT.ALL_PROCESSING
  }
  if (CountStatus.delivered > 0) {
    return TRACKING_PO_STT.PART_DEVLIRED
  }
  if (CountStatus.shipped > 0) {
    return TRACKING_PO_STT.PART_SHIPPED
  }
  return TRACKING_PO_STT.DEFAULT
}

export function mappingOrderRelative(order: Types.OrderShopHelp) {
  const { name, order_lines, payment, domain } = order
  return {
    name,
    domain,
    item: order_lines![0],
    total_items: order_lines?.length,
    total: getCurrency(payment?.total_amount || 0),
    tracking_status: convertTrackingPOStatus(order)
  }
}

export function updateTrackingInfo(data: Types.TrackingInfo) {
  if (
    data.tracking_date?.tracking_available_date &&
    data.status !== 'SHIPPED' &&
    data.status !== 'DELIVERED' &&
    data.status !== 'PARTIALLY_DELIVERED'
  ) {
    data.status = 'SHIPPED'
  }
  return data
}

export const buildClaimOrderDescription = (
  tracking_infos: Types.TrackingInfo[],
  selectedIds: string[],
  description: string
) => {
  if (selectedIds.length === 0) {
    return `<b>No variants claim provided</b><br/><b>Description:</b><br/>${description}`
  }
  if (selectedIds.length === tracking_infos.length) {
    return `<b>All variants claimed</b></br/><b>Description:</b><br/>${description}`
  }
  let text = '<b>Claimed variants:</b><br/>'
  selectedIds.forEach((po_item_id: string) => {
    const variant = tracking_infos.find((v: Types.TrackingInfo) => {
      return v.po_item_id === po_item_id
    })
    if (variant) {
      const variantProps =
        variant.variant_properties &&
        variant.variant_properties.map((prop: Types.PropertyVariant) => prop.value).join(' / ')
      text += `${variantProps}<br/>`
    }
  })
  text += `<b>Description:</b><br/>${description}`
  return text
}
