export function invertColor(color: string) {
  const luminance = hexColorLuminance(color)

  if (luminance > 0.55) {
    return 'rgba(#000, 0.7)'
  }

  return '#fff'
}

function hexColorLuminance(color: string) {
  let hex = color

  if (!color) {
    return 0
  }

  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1)
  }

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const red = parseInt(hex.slice(0, 2), 16)
  const green = parseInt(hex.slice(2, 4), 16)
  const blue = parseInt(hex.slice(4, 6), 16)

  return rgbColorLuminance(red, green, blue)
}

function rgbColorLuminance(red: number, green: number, blue: number) {
  const r = rgbLuminance(red)
  const g = rgbLuminance(green)
  const b = rgbLuminance(blue)

  return r * 0.2126 + g * 0.7152 + b * 0.0722
}

function rgbLuminance(code: number) {
  const value = code / 255

  if (value < 0.03928) {
    return value / 12.92
  }

  return ((value + 0.055) / 1.055) ** 2
}
