export const noDomain = (host: string) =>
  ['localhost', '127.0.0.1'].some((path) => host.includes(path))

export const excluded = (path?: string) =>
  !path || /(_next|\.(js|css|jpg|jpeg|png|gif|ico|svg|json))/.test(path)

export const toJson = (data: string | Uint8Array) => JSON.parse(Buffer.from(data).toString('utf8'))

// export const shopDNSTemplate = (domain: string) => `domains/${domain}.json`

// export const shopSettingTemplate = (shopId: string) => `shops/${shopId}/setting.json`

// export const productsTemplate = (shopId: string) => `shops/${shopId}/products/`

// export const designsTemplate = (shopId: string) => `shops/${shopId}/designs/`
// export const previewsTemplate = (shopId: string) => `shops/${shopId}/previews/`

// export const reviewsTemplate = (shopId: string, productId: string) =>
//   `shops/${shopId}/reviews/${productId}.json`

export const deepCloneObject = (inObject: Record<string, any>): any => {
  if (typeof inObject !== 'object' || inObject === null) {
    return inObject // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  const outObject = Array.isArray(inObject) ? [] : {}

  for (const key in inObject) {
    // @ts-ignore
    const value = inObject[key]

    // Recursively (deep) copy for nested objects, including arrays
    // @ts-ignore
    outObject[key] = deepCloneObject(value)
  }

  return outObject
}

export const parseCookie = (str: string) =>
  str
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc: { [key: string]: string }, v: string[]) => {
      acc[decodeURIComponent(v[0]?.trim())] = decodeURIComponent(v[1]?.trim())
      return acc
    }, {})
