const listeners = new Map()

export enum SubscribeStateKey {
  GET_TICKETS = 'GET_TICKETS',
  DISABLE_GLOBAL_SCROLL = 'DISABLE_GLOBAL_SCROLL',
  OPEN_CONTACT_US = 'OPEN_CONTACT_US',
  LOGIN_FORM = 'LOGIN_FORM',
  ORDER_STATUS = 'ORDER_STATUS'
}

export function subscribeState(key: SubscribeStateKey, callback: (data: any) => void) {
  const callbackStack = [callback]
  if (listeners.has(key)) {
    callbackStack.push(...listeners.get(key))
  }
  listeners.set(key, callbackStack)
}

export function unsubscribeState(key: SubscribeStateKey) {
  if (listeners.has(key)) {
    listeners.delete(key)
  }
}

export function fireNextState(key: SubscribeStateKey, data: any) {
  if (!listeners.get(key)) {
    return
  }
  listeners.get(key).forEach((callback: (e: any) => any) => callback(data))
}
