import axios from 'axios'
import { serviceOptions } from './cmsOrder'
import getConfig from 'next/config'
import { getCookie } from 'src/helpers/getCookie'
import { versionCookie } from '@libs/client/helpers'

// const slug = process.browser ? window.location.pathname.split('/')[1] : ''
const userCookie = getCookie('_version_') || ''
// const pCookie = slug ? getCookie(`pversion_${slug}`) : getCookie('pversion') || ''
const instance = axios.create({
  baseURL: getConfig().publicRuntimeConfig.apiBasePath,
  // timeout: 10000,
  headers: {
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    version: `${versionCookie.includes(userCookie) ? userCookie : ''}`
    // pVersion: `${pCookie}`
  }
})

serviceOptions.axios = instance

export * from './cmsOrder'
