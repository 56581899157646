import axios from 'axios'

export const getPageBySlug = async (storeId: string, slug: string, pversion?: string) => {
  const _sellpages: S3Types.pages = (
    await axios.get(
      `/api/stores/${storeId}/pages?subpaths=${JSON.stringify([slug])}&pversion=${pversion || ''}`
    )
  )?.data as S3Types.pages

  return _sellpages?.[slug]
}
