import {
  removeUnavailableProperties,
  injectSellable,
  removeUnavailablePropertiesUpsell,
  removeBunderVariant
} from '@libs/server'
import { _mq_active_type, _mq_full_type, _mq_inactive_type } from '.'
import {
  GetDomainsParams,
  GetMqParams,
  GetPageVersionsParams,
  GetPagesParams,
  GetPaygatesParams,
  GetProductBasesParams,
  GetProductPagesParams,
  GetPublishersParams,
  GetStoresParams
} from '../adapters/types'
import { DataFetchingService } from '../services'
import { getDurationInSeconds, logger } from '../ultilities'

export async function fetchMq(dataFetchingService: DataFetchingService, params?: GetMqParams) {
  const t = process.hrtime()
  try {
    const data = await dataFetchingService.getMq(params)
    logger.info(`📝 MQ loaded in: ${getDurationInSeconds(t).toLocaleString()}s`)
    if (process._repository) process._repository['_mq'] = data
    if (params?.files) {
      Object.entries(data)?.map(([file, content]) => {
        if (process._repository?.['_mq'])
          process._repository['_mq'][file as _mq_full_type] = content
      })
    } else {
      if (process._repository) process._repository['_mq'] = data
    }
  } catch (err: any) {
    return logger.error(err)
  }
}

export async function fetchMqActive(
  dataFetchingService: DataFetchingService,
  params?: GetMqParams
) {
  const t = process.hrtime()
  try {
    const data = await dataFetchingService.getMqActive(params)
    logger.info(`📝 MQ  Active loaded in: ${getDurationInSeconds(t).toLocaleString()}s`)
    if (process._repository) process._repository['_mq_active'] = data
    if (params?.files) {
      Object.entries(data)?.map(([file, content]) => {
        if (process._repository?.['_mq_active'])
          process._repository['_mq_active'][file as _mq_active_type] = content
      })
    } else {
      if (process._repository) process._repository['_mq_active'] = data
    }
  } catch (err: any) {
    return logger.error(err)
  }
}
export async function fetchMqInActive(
  dataFetchingService: DataFetchingService,
  params?: GetMqParams
) {
  const t = process.hrtime()
  try {
    const data = await dataFetchingService.getMqInActive(params)
    logger.info(`📝 MQ In Active loaded in: ${getDurationInSeconds(t).toLocaleString()}s`)
    if (process._repository) process._repository['_mq_inactive'] = data
    if (params?.files) {
      Object.entries(data)?.map(([file, content]) => {
        if (process._repository?.['_mq_inactive'])
          process._repository['_mq_inactive'][file as _mq_inactive_type] = content
      })
    } else {
      if (process._repository) process._repository['_mq_inactive'] = data
    }
  } catch (err: any) {
    return logger.error(err)
  }
}

export async function fetchDomains(
  dataFetchingService: DataFetchingService,
  params?: GetDomainsParams
) {
  const t = process.hrtime()
  try {
    const data = await dataFetchingService.getDomains(params)
    logger.info(`🌐 Domains loaded in: ${getDurationInSeconds(t).toLocaleString()}s`)
    if (params?.domains) {
      Object.entries(data)?.map(([domain, store_id]) => {
        if (process._repository['domains']) process._repository['domains'][domain] = store_id
      })
    } else {
      if (process._repository) process._repository['domains'] = data
    }
  } catch (err: any) {
    return logger.error(err)
  }
}

export async function fetchDefaultPaygate(dataFetchingService: DataFetchingService) {
  const t = process.hrtime()
  try {
    const data = await dataFetchingService.getDefaultPaygate()
    logger.info(`💸 Default Paygate loaded in: ${getDurationInSeconds(t).toLocaleString()}s`)
    if (process._repository) process._repository['default_paygate'] = data
  } catch (err: any) {
    return logger.error(err)
  }
}

export async function fetchPaygates(
  dataFetchingService: DataFetchingService,
  params?: GetPaygatesParams
) {
  const t = process.hrtime()
  try {
    let data = await dataFetchingService.getPaygates(params)
    logger.info(`💵 Paygates loaded in: ${getDurationInSeconds(t).toLocaleString()}s`)
    data = convertPaygates(data)
    if (params?.gateway_ids) {
      Object.entries(data)?.map(([gateway_id, paygate]) => {
        if (process._repository['paygates']) process._repository['paygates'][gateway_id] = paygate
      })
    } else {
      if (process._repository) process._repository['paygates'] = data
    }
  } catch (err: any) {
    return logger.error(err)
  }
}

export async function fetchProductBases(
  dataFetchingService: DataFetchingService,
  params?: GetProductBasesParams
) {
  const t = process.hrtime()
  try {
    const data = await dataFetchingService.getProductBases(params)
    logger.info(`🛍️ Product bases loaded in: ${getDurationInSeconds(t).toLocaleString()}s`)
    if (params?.pbase_ids) {
      Object.entries(data)?.map(([pbase_id, pbase]) => {
        if (process._repository['pbases']) process._repository['pbases'][pbase_id] = pbase
      })
    } else {
      if (process._repository) process._repository['pbases'] = data
    }
  } catch (err: any) {
    return logger.error(err)
  }
}

export async function fetchProductPages(
  dataFetchingService: DataFetchingService,
  params?: GetProductPagesParams
) {
  const t = process.hrtime()
  try {
    const data = await dataFetchingService.getProductPages(params)
    logger.info(`📃 Product pages loaded in: ${getDurationInSeconds(t).toLocaleString()}s`)
    if (params?.ppage_ids) {
      Object.entries(data)?.map(([ppage_id, ppage]) => {
        if (process._repository['ppages']) process._repository['ppages'][ppage_id] = ppage
      })
    } else {
      if (process._repository) process._repository['ppages'] = data
    }
    Object.entries(data).map(([id, page]) => {
      const pshort = page.setting?.settings?.general?.short
      const pslug = page.setting?.settings?.general?.slug
      if (pshort) {
        if (process._repository['ppages_mapping']) {
          const _id = process._repository['ppages_mapping'][pshort]
          if (_id && _id !== id)
            console.warn(`Duplicated product page short: ${pshort}, ${_id}, ${id}`)
          process._repository['ppages_mapping'][pshort] = id
        }
      }
      if (pslug) {
        if (process._repository['ppages_mapping']) {
          const _id = process._repository['ppages_mapping'][pslug]
          if (_id && _id !== id)
            console.warn(`Duplicated product page slug: ${pslug}, ${_id}, ${id}`)
          process._repository['ppages_mapping'][pslug] = id
        }
      }
    })
  } catch (err: any) {
    return logger.error(err)
  }
}

export async function fetchPublishers(
  dataFetchingService: DataFetchingService,
  params?: GetPublishersParams
) {
  const t = process.hrtime()
  try {
    const data = await dataFetchingService.getPublishers(params)
    logger.info(`🤑 Publishers loaded in: ${getDurationInSeconds(t).toLocaleString()}s`)
    if (params?.publisher_ids) {
      Object.entries(data)?.map(([publisher_id, publisher]) => {
        if (process._repository['publishers'])
          process._repository['publishers'][publisher_id] = publisher
      })
    } else {
      if (process._repository) process._repository['publishers'] = data
    }
  } catch (err: any) {
    return logger.error(err)
  }
}

export async function fetchDefaultStore(dataFetchingService: DataFetchingService) {
  const t = process.hrtime()
  try {
    const data = await dataFetchingService.getDefaultStore()
    logger.info(`🏪 Default store loaded in: ${getDurationInSeconds(t).toLocaleString()}s`)
    if (process._repository) process._repository['default_store'] = data
  } catch (err: any) {
    return logger.error(err)
  }
}
export async function fetchConfigSurveys(dataFetchingService: DataFetchingService) {
  const t = process.hrtime()
  try {
    const data = await dataFetchingService.getConfigSurveys()
    logger.info(`🏪 Default config surveys loaded in: ${getDurationInSeconds(t).toLocaleString()}s`)
    if (process._repository) process._repository['surveys'] = data
  } catch (err: any) {
    return logger.error(err)
  }
}

export async function fetchP10nStore(dataFetchingService: DataFetchingService) {
  const t = process.hrtime()
  try {
    const data = await dataFetchingService.getP10nStore()
    logger.info(`🏪 Default store loaded in: ${getDurationInSeconds(t).toLocaleString()}s`)
    if (process._repository) process._repository['p10n_store'] = data
  } catch (err: any) {
    return logger.error(err)
  }
}
export async function fetchPages(
  dataFetchingService: DataFetchingService,
  params?: GetPagesParams
) {
  const t = process.hrtime()
  try {
    const data = await dataFetchingService.getPages(params)
    logger.info(`🏬 Pages loaded in: ${getDurationInSeconds(t).toLocaleString()}s`)
    if (params?.pages_ids) {
      Object.entries(data)?.map(([page_id, pages]) => {
        if (process._repository['pages']) process._repository['pages'][page_id] = pages
        updatePage(pages)
      })
    } else {
      if (process._repository) process._repository['pages'] = data
    }
  } catch (err: any) {
    return logger.error(err)
  }
}
export async function fetchPageVersions(
  dataFetchingService: DataFetchingService,
  params?: GetPageVersionsParams
) {
  const t = process.hrtime()
  try {
    const data = await dataFetchingService.getPageVersion(params)
    logger.info(`🏬 Page Versions loaded in: ${getDurationInSeconds(t).toLocaleString()}s`)
    if (params?.page_versions_ids) {
      Object.entries(data)?.map(([page_version_id, page_versions]) => {
        if (process._repository['page_versions'])
          process._repository['page_versions'][page_version_id] = page_versions
        updatePage(page_versions as S3Types.page)
      })
    } else {
      if (process._repository) process._repository['page_versions'] = data
    }
  } catch (err: any) {
    return logger.error(err)
  }
}

export async function fetchStores(
  dataFetchingService: DataFetchingService,
  params?: GetStoresParams
) {
  const t = process.hrtime()
  try {
    const data = await dataFetchingService.getStores(params)
    logger.info(`🏬 Stores loaded in: ${getDurationInSeconds(t).toLocaleString()}s`)
    if (params?.store_ids) {
      Object.entries(data)?.map(([store_id, store]) => {
        if (process._repository['stores']) process._repository['stores'][store_id] = store
      })
    } else {
      if (process._repository) process._repository['stores'] = data
    }
  } catch (err: any) {
    return logger.error(err)
  }
}

function convertPaygates(paygates: S3Types.paygates): S3Types.paygates {
  return Object.fromEntries(
    Object.entries(paygates).map(([id, paygate]) => [
      id,
      {
        id: paygate.id,
        type: paygate.type,
        adapter: paygate.adapter,
        name: paygate.name,
        credential_clientid: paygate.credential_clientid,
        cards: paygate.cards,
        contact: paygate.contact,
        supportjs: paygate.supportjs
      }
    ])
  )
}

export const updatePage = (page: S3Types.page) => {
  try {
    if (page) {
      injectSellable(page?.setting)
      removeUnavailableProperties(page?.setting?.variants)
      if (page?.setting?.settings?.configurations?.hide_clearance) {
        page.setting.settings.general.upsell = false
      }

      if (
        page?.setting?.settings?.general?.upsell ||
        page?.setting?.settings?.configurations?.hide_clearance
      ) {
        removeUnavailablePropertiesUpsell((page as S3Types.page)?.setting?.variants)
        removeBunderVariant((page as S3Types.page)?.setting)
      }
    }
  } catch (error) {
    console.error('Error when updating page', `${error}`)
  }
}
