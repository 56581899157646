import React from 'react'
import css from 'styled-jsx/css'
import Countdown from './Countdown'
import { useGlobalState } from '@libs/client'

const CountExpiredTime = () => {
  const [tokenDetails] = useGlobalState<any>('token_detail')

  return (
    <>
      <style jsx>{style}</style>
      {tokenDetails != null && (
        <div className="preview-content-expired">
          This page is for preview content only. It will be expired in{' '}
          <Countdown time={tokenDetails.countdown_time} className="is-inline has-text-link" />{' '}
        </div>
      )}
    </>
  )
}

export default CountExpiredTime

const style = css`
  .preview-content-expired {
    text-align: center;
    background: #ffffff;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    color: #000000;
    padding: 16px 0;
  }
`
