import { isEmpty } from '@ui/helpers'
import logRocket from 'logrocket'
import getConfig from 'next/config'

const enabledEnvironments = ['production']
const env = process.env.NODE_ENV || 'local'

export interface ILogRocketConfig {
  fullSession?: boolean
  id?: string
}

export interface ILogRocketContainer {
  logRocket?: ILogRocket
}

export interface ILogRocket {
  init: () => void
  identify: (user: Types.PlainObject) => void
  track: (event: string) => void
  identifyContact: (user: Types.PlainObject) => void
}

const config: ILogRocketConfig = {}

const options = {
  console: {
    isEnabled: false
  },
  network: {
    requestSanitizer: (request: any) => {
      if (request.url.toLowerCase().includes('/cart/pay/cc')) {
        // let body = request.body

        try {
          // if (typeof body === 'string') {
          //   body = JSON.parse(body)
          // }
          // delete body.credit_card_info
          request.body = ''
        } catch (err: any) {
          console.error('Init log rocket error. Cannot sanitize network', err)
          return request
        }
      }

      return request
    }
  }
}

function isReady() {
  const url = logRocket.sessionURL

  if (config.id && url?.includes(config.id)) {
    return true
  }

  return false
}

const LogRocketContainer: ILogRocketContainer = {}

const logRocketId = getConfig().publicRuntimeConfig.logRocketId

export default function getLogRocket(): ILogRocketContainer {
  if (!isEmpty(LogRocketContainer)) {
    return LogRocketContainer // singleton
  }

  const init = () => {
    if (!enabledEnvironments.includes(env) || !logRocketId || isReady()) {
      return null
    }
    logRocket.init(logRocketId, options)
  }

  const identify = (user: Types.PlainObject = {}) => {
    if (!enabledEnvironments.includes(env)) {
      return null
    }
    logRocket.getSessionURL(() => {
      const name = `${user.firstName || ''} ${user.lastName || ''}`.trim()
      logRocket.identify(user.email, {
        name,
        email: user.email
      })
    })
  }

  const identifyContact = (user: Types.PlainObject = {}) => {
    if (!enabledEnvironments.includes(env)) {
      return null
    }
    logRocket.getSessionURL(() => {
      const name = `${user.firstName || ''} ${user.lastName || ''} ${user.order || ''}`.trim()
      const sessionId = user.email ?? user.phone
      logRocket.identify(sessionId, {
        name,
        email: user.email
      })
    })
  }

  const track = (event: string) => {
    if (!enabledEnvironments.includes(env)) {
      return null
    }

    logRocket.getSessionURL(() => {
      logRocket.track(event)
    })
  }

  LogRocketContainer.logRocket = {
    init,
    identify,
    track,
    identifyContact
  }

  return LogRocketContainer
}
