import { iconPath } from './images'

export function payflowErrorMapping(code: number | string) {
  const messages: Types.PlainObject = {
    2: 'Does not support this credit card type',
    3: 'Invalid transaction type',
    4: 'Invalid amount format',
    5: 'Invalid merchant information. Contact your bank account acquirer to resolve this problem',
    6: 'Invalid or unsupported currency code',
    7: 'Invalid information entered',
    10: 'Too many line items',
    11: 'Client time-out waiting for response',
    12: 'Declined, please contact your card issuing bank to resolve',
    20: 'Cannot find the customer reference number',
    22: 'Invalid ABA number',
    23: 'Invalid account number. Check credit card number and bank account number and re-submit',
    24: 'Invalid expiration date. Check card expiration date and re-submit',
    25: 'Does not support this credit card type',
    30: 'Duplicate transaction',
    100: 'Transaction type not supported by host',
    102: 'The financial host was unable to communicate with the external processor',
    103: 'Error reading response from host',
    104: 'Timeout waiting for processor response, please try again',
    105: 'Credit error',
    106: 'Host not available',
    109: 'Time-out waiting for host response, please try again',
    112: 'Address and zip code do not match',
    114: 'Card Security Code (CSC) Mismatch',
    115: 'System busy, try again later',
    125: 'Declined by Fraud Protection Services Filter',
    126: 'Declined by Fraud Protection Services Filter',
    127: 'Declined by Fraud Protection Services Filter',
    128: 'Declined by Fraud Protection Services Filter',
    150: 'Issuing bank timed out',
    151: 'Issuing bank unavailable',
    170: 'Declined by Fraud Protection Services Filter',
    1000: 'Generic host error',
    1001: 'Authentication Service unavailable',
    1002: 'Transaction timeout',
    1003: 'Invalid client version',
    1004: 'Invalid timeout value',
    1011: 'Authentication Service unavailable',
    1012: 'Authentication Service unavailable',
    1013: 'Authentication Service unavailable',
    1014: '3-D Secure error',
    1016: '3-D Secure error',
    1017: '3-D Secure error',
    1021: 'Invalid card type',
    1022: 'Invalid or missing currency code',
    1023: '3-D Secure error',
    1041: 'Validate Authentication failure',
    1042: 'Validate Authentication failure',
    1043: 'Validate Authentication failure',
    1044: 'Validate Authentication failure',
    1045: 'Validate Authentication failure',
    1046: 'Validate Authentication failure',
    1047: 'Validate Authentication failure',
    1048: 'Validate Authentication failure',
    1049: 'Validate Authentication failure',
    1050: 'Validate Authentication failure',
    1051: 'Validate Authentication failure',
    1052: 'Validate Authentication failure'
  }

  return messages[code] || 'Payment failure. Please check card information and try again'
}

export function paypalErrorMapping(code: string) {
  const messages: Types.PlainObject = {
    AUTHENTICATION_FAILURE: 'Authentication failed',
    AUTHORIZATION_AMOUNT_EXCEEDED:
      'The specified authorization amount exceeded the allowable limit',
    CARD_TYPE_NOT_SUPPORTED: 'This card type is not supported',
    INVALID_SECURITY_CODE_LENGTH: 'The security_code length is invalid for the specified card type',
    CITY_REQUIRED:
      'The specified country requires a city. Specify a city and try the request again',
    COMPLIANCE_VIOLATION: 'Transaction cannot be processed due to a possible compliance violation',
    CONSENT_NEEDED: 'Authorization failed due to insufficient permissions',
    CURRENCY_NOT_SUPPORTED_FOR_CARD_TYPE:
      'The currency code is not supported for direct card payments for this card type',
    CURRENCY_NOT_SUPPORTED_FOR_COUNTRY:
      'Currency code not supported for direct card payments in this country',
    INSTRUMENT_DECLINED:
      'The funding instrument presented was either declined by the processor or bank',
    INTERNAL_SERVER_ERROR: 'There was an internal server error of PayPal',
    INTERNAL_SERVICE_ERROR: 'There was an internal server error of PayPal',
    INVALID_ACCOUNT_STATUS: 'Account validations failed',
    INVALID_COUNTRY_CODE: 'Country code is invalid',
    INVALID_CURRENCY_CODE: 'Currency code is invalid or is not currently supported',
    MAX_AUTHORIZATION_COUNT_EXCEEDED:
      'The maximum number of authorizations that are allowed for the order was reached',
    MAX_VALUE_EXCEEDED:
      'Should be less than or equal to 9,999,999.99. Try the request again with a different value',
    MISSING_SHIPPING_ADDRESS: 'The shipping address is required',
    MULTI_CURRENCY_ORDER: 'Multiple differing values of currency_code are not supported',
    MULTIPLE_SHIPPING_ADDRESS_NOT_SUPPORTED: 'Multiple shipping addresses are not supported',
    INVALID_PICKUP_ADDRESS: 'Invalid shipping address',
    NOT_AUTHORIZED: 'Authorization failed due to insufficient permissions',
    NOT_ENABLED_FOR_CARD_PROCESSING: 'Not enabled for card processing',
    ORDER_ALREADY_AUTHORIZED: 'Order already authorized',
    ORDER_ALREADY_CAPTURED: 'Order already captured',
    ORDER_ALREADY_COMPLETED: 'The order cannot be patched after it is completed',
    ORDER_CANNOT_BE_SAVED:
      'The requested action could not be performed, semantically incorrect, or failed business validation',
    ORDER_COMPLETED_OR_VOIDED: 'Order is voided or completed and hence cannot be authorized',
    ORDER_EXPIRED: 'Order is expired and hence cannot be authorized',
    PAYEE_ACCOUNT_INVALID: 'Payee account specified is invalid',
    PAYEE_ACCOUNT_LOCKED_OR_CLOSED: 'Payee account is locked or closed',
    PAYEE_ACCOUNT_RESTRICTED: 'The merchant account is restricted',
    PAYER_COUNTRY_NOT_SUPPORTED: 'Your country is not supported',
    PAYEE_NOT_ENABLED_FOR_CARD_PROCESSING: 'Payee not enabled for card processing',
    POSTAL_CODE_REQUIRED: 'The specified country requires a postal code',
    SHIPPING_ADDRESS_INVALID: 'Provided shipping address is invalid',
    TRANSACTION_BLOCKED_BY_PAYEE:
      'The transaction was blocked by the payee’s Fraud Protection settings',
    TRANSACTION_LIMIT_EXCEEDED: 'Total payment amount exceeded transaction limit',
    TRANSACTION_RECEIVING_LIMIT_EXCEEDED: `The transaction exceeds the payee's receiving limit`,
    TRANSACTION_REFUSED: 'The transaction was refused'
  }

  return messages[code] || 'Payment failure. Please try again.'
}

// TODO: Return all card type as link to img
// TODO: Only check for first 4 digits => double check regex
export function getCardType(cardNumber: string, paymentTypeOrder = ''): string {
  // visa
  let re = new RegExp('^4')
  if (cardNumber.match(re) != null) {
    return iconPath('visa.svg')
  }
  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  re = new RegExp(/^5[1-5][0-9]{2}/)
  if (re.test(cardNumber)) {
    return iconPath('master.svg')
  }

  // AMEX
  re = new RegExp('^3[47]')
  if (cardNumber.match(re) != null) {
    return iconPath('amex.svg')
  }

  // Discover
  re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)')
  if (cardNumber.match(re) != null) {
    return 'Discover'
  }

  // Diners
  re = new RegExp('^36')
  if (cardNumber.match(re) != null) {
    return 'Diners'
  }

  // Diners - Carte Blanche
  re = new RegExp('^30[0-5]')
  if (cardNumber.match(re) != null) {
    return 'Diners - Carte Blanche'
  }

  // JCB
  re = new RegExp('^35(2[89]|[3-8][0-9])')
  if (cardNumber.match(re) != null) {
    return 'JCB'
  }

  // Visa Electron
  re = new RegExp('^(4026|417500|4508|4844|491(3|7))')
  if (cardNumber.match(re) != null) {
    return iconPath('visa.svg')
  }

  return getPaymentCardTypeImg(paymentTypeOrder) || ''
}
// VISA = 'VISA',
// MASTER = 'MASTER',
// DISCOVER = 'DISCOVER',
// AMEX = 'AMEX',
// JCB = 'JCB',
// UNIONPAY = 'UNIONPAY',
// DINERS_CLUB = 'DINERS_CLUB'
const getPaymentCardTypeImg = (paymentTypeOrder = ''): string => {
  switch (paymentTypeOrder) {
    case 'diners':
    case 'diners-club':
      return iconPath(`diners_club.svg`)
    case 'mastercard':
      return iconPath('master.svg')
    case 'american-express':
    case 'american express':
      return iconPath('amex.svg')
    case 'master-card':
      return iconPath('master.svg')

    default:
      return iconPath(`${paymentTypeOrder.toLocaleLowerCase()}.svg`)
  }
}

export const checkZipCode = (zipCode: string) => {
  const zc =
    (zipCode.split('-')[0] || '').trim().length > 5
      ? (zipCode.split('-')[0] || '')?.trim()?.substring(0, 5)
      : (zipCode.split('-')[0] || '').trim()
  if (isNaN(parseInt(zc || ''))) return false
  return !rangeZipCode.some((x) => parseInt(zc || '') >= x[0] && parseInt(zc || '') <= x[1])
}

export function trunc(num: number, digits: number) {
  const numS = num.toString()
  const decPos = numS.indexOf('.')
  const substrLength = decPos == -1 ? numS.length : 1 + decPos + digits
  const trimmedResult = numS.substr(0, substrLength)
  return parseFloat(trimmedResult)
}

export function convertNumber(numFrom: number, numberTo: number) {
  const numberFrist = numFrom * 100
  return numberFrist / (numberTo * 100)
}
export function multiNumber(numFrom: number, numberTo: number) {
  const numberFrist = numFrom * 100
  return (numberFrist * (numberTo * 100)) / 10000
}
export function sumNumber(arr: number[]) {
  arr = arr?.map((x) => x * 100)
  return (
    arr?.reduce((next, acc) => {
      acc += next
      return acc
    }, 0) / 100
  )
}

const rangeZipCode = [
  [0, 499],
  [600, 999],
  [9000, 9999],
  [21300, 21399],
  [26900, 26999],
  [34000, 34099],
  [34300, 34399],
  [34500, 34599],
  [34800, 34899],
  [35300, 35399],
  [41900, 41999],
  [42800, 42999],
  [51700, 51999],
  [52900, 52999],
  [53300, 53399],
  [53600, 53699],
  [55200, 55299],
  [56800, 56999],
  [57800, 57999],
  [58900, 58999],
  [62100, 62199],
  [63200, 63299],
  [64200, 64399],
  [65900, 65999],
  [66300, 66399],
  [68200, 68299],
  [69400, 69999],
  [70200, 70299],
  [70900, 70999],
  [71500, 71599],
  [73200, 73299],
  [74200, 74299],
  [77100, 77199],
  [81700, 81999],
  [83900, 83999],
  [84800, 84999],
  [85400, 85499],
  [85800, 85899],
  [86100, 86299],
  [86600, 86699],
  [86700, 86999],
  [87200, 87299],
  [87600, 87699],
  [88600, 88899],
  [89200, 89299],
  [89600, 89699],
  [89900, 89999],
  [90900, 90999],
  [92900, 92999],
  [96200, 96999],
  [98700, 98799],
  [99500, 99999]
]
