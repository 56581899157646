import { useSetting, useVersionCookie } from '@ui/hooks'
import getConfig from 'next/config'

// Refactor cdnBasePath to be a custom hook
export const useCdnBasePath = (fromIo?: boolean) => {
  const versionCookie = useVersionCookie()
  const [storeSetting] = useSetting('store')
  const cookie = storeSetting?.version || versionCookie
  const config = getConfig().publicRuntimeConfig // Move getConfig call inside the hook

  return cookie === 'vna2' && fromIo ? `${config.cdnVideoNa}` : (cookie === 'vcdn3' || cookie === 'acdn3' ? `${config.cdnVideo3}` : `${config.cdnVideo}`)
}
const cdnBasePathImg = getConfig().publicRuntimeConfig.cdnBasePath
// const previewVideoPath = getConfig().publicRuntimeConfig.cdnVideo

export const videoPath = (url: string, basepath = getConfig().publicRuntimeConfig.cdnVideo) =>
  `${basepath}${url}`
export const videoPathFallback = (url: string) => `${cdnBasePathImg}/${url}`
export const previewVideo = (path = '', basepath = getConfig().publicRuntimeConfig.cdnVideo) =>
  `${basepath}${path}`
export const getFallbackVideoPath = (fileName: string, page_id?: string) => {
  return `${cdnBasePathImg}/c/96x96/p/${page_id || ''}/i/${fileName}`
}
