export function getUtms(query: Types.PlainObject) {
  const utms: Types.PlainObject = {}

  for (const key in query) {
    if (key.toLowerCase().indexOf('utm_') === 0) {
      utms[key] = query[key]
    }
  }

  return utms
}

export function buildUtmQueries(utms: Types.PlainObject) {
  let queryString = ''
  for (const key in utms) {
    if (utms[key]) {
      queryString += `&${key}=${utms[key]}`
    }
  }

  return queryString.slice(1)
}

export function getUtmQueries(query: Types.PlainObject) {
  const utms = getUtms(query)

  return buildUtmQueries(utms)
}
