import * as Sentry from '@sentry/node'
import getConfig from 'next/config'
import { Integrations } from '@sentry/tracing'
import { CaptureConsole } from '@sentry/integrations'

const isBrowser = () => process.browser

let initialize = false
export function initSentry() {
  if (initialize) return
  initialize = true
  const config = getConfig().publicRuntimeConfig
  const sentryDsn = isBrowser() ? config.sentryDsn : process.env.Sentry__Dsn
  const sentryEnvironment = isBrowser() ? config.env : process.env.env
  const buildVersion = isBrowser() ? config.buildVersion : process.env.BUILD_VERSION

  if (sentryDsn) {
    const integrations = [
      new Integrations.BrowserTracing(),
      new CaptureConsole({
        levels: ['error', 'warn']
      })
    ]
    Sentry.init({
      ignoreErrors: [
        /^\[Meta Pixel\] - Invalid PixelID: null.$/,
        /^\[Facebook Pixel\] - Invalid PixelID.*$/,
        /^\[Meta Pixel\] - Pixel.*not found$/,
        /^\[Facebook Pixel\] - Pixel .* not found$/,
        /^Client input error.*$/,
        /^LogRocket has already been loaded, loading a second instance is not supported.$/,
        /^LogRocket filter manager is using too much memory. Disabling.$/,
        // /Failed to load Stripe.js/,
        /^TypeError.*$/,
        /^LogRocket.*$/,
        /This version has Break Change/,
        /Cannot assign to read only property/,
        /^Cannot freeze*$/,
        /^Attempted to assign to readonly property.*$/,
        /^Error: AbortError*$/,
        /^[Meta Pixel] - Duplicate Pixel ID*$/
      ],
      beforeSend(event) {
        // Check if the event contains a message and matches the regex pattern
        // const regex = /^[Meta Pixel] - Duplicate Pixel ID*$/ // Replace with your actual regex
        if (event.message?.includes('Duplicate Pixel ID')) {
          // Discard the event by returning null if it matches the regex
          return null
        }
        // Otherwise, send the event to Sentry
        return event
      },
      integrations,
      dsn: sentryDsn,
      environment: sentryEnvironment,
      release: buildVersion
    })
  }
}
