import useAxios from '../useAxios'
import getConfig from 'next/config'
import { isEmpty } from '@ui/helpers'

interface IPreviewContainer {
  preview: (id: string) => Promise<S3Types.page>
}

const previewBasePath = getConfig().publicRuntimeConfig.previewBasePath

const previewContainer = {} as IPreviewContainer

export const getPreviewService = (): IPreviewContainer => {
  if (!isEmpty(previewContainer)) {
    return previewContainer
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { instancePreview } = useAxios(previewBasePath)

  previewContainer.preview = (id: string) => {
    return instancePreview.get(`/admin/pcm/product/page/${id}/preview`)
  }

  return previewContainer
}
