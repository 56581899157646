import getConfig from 'next/config'
import NodeRSA from 'node-rsa'

const rsaPublicKey = getConfig().publicRuntimeConfig.rsaPublicKey

export const toDictionary = <T, K>(
  arr: T[],
  keyFn: (item: T) => string,
  itemFn: (item: T) => K
) => {
  return arr.reduce((total, item) => {
    total[keyFn(item)] = itemFn(item)
    return total
  }, {} as { [key: string]: K })
}

export const rsaEncryptData = (data: any) => {
  if (process.browser) {
    try {
      const publicKey = new NodeRSA(rsaPublicKey, 'pkcs8-public')
      return publicKey.encrypt(data, 'base64')
    } catch (err: any) {
      console.error(err)
      return ''
    }
  }
  return ''
}

export const mappingStateCode = {
  alabama: 'al',
  alaska: 'ak',
  americansamoa: 'as',
  arizona: 'az',
  arkansas: 'ar',
  california: 'ca',
  colorado: 'co',
  connecticut: 'ct',
  delaware: 'de',
  districtofcolumbia: 'dc',
  federatedstatesofmicronesia: 'fm',
  florida: 'fl',
  georgia: 'ga',
  guam: 'gu',
  hawaii: 'hi',
  idaho: 'id',
  illinois: 'il',
  indiana: 'in',
  iowa: 'ia',
  kansas: 'ks',
  kentucky: 'ky',
  louisiana: 'la',
  maine: 'me',
  marshallislands: 'mh',
  maryland: 'md',
  massachusetts: 'ma',
  michigan: 'mi',
  minnesota: 'mn',
  mississippi: 'ms',
  missouri: 'mo',
  montana: 'mt',
  nebraska: 'ne',
  nevada: 'nv',
  newhampshire: 'nh',
  newjersey: 'nj',
  newmexico: 'nm',
  newyork: 'ny',
  northcarolina: 'nc',
  northdakota: 'nd',
  northernmarianaislands: 'mp',
  ohio: 'oh',
  oklahoma: 'ok',
  oregon: 'or',
  palau: 'pw',
  pennsylvania: 'pa',
  puertorico: 'pr',
  rhodeisland: 'ri',
  southcarolina: 'sc',
  southdakota: 'sd',
  tennessee: 'tn',
  texas: 'tx',
  utah: 'ut',
  vermont: 'vt',
  virginislands: 'vi',
  virginia: 'va',
  washington: 'wa',
  westvirginia: 'wv',
  wisconsin: 'wi',
  wyoming: 'wy'
}
