const customScriptDomains = [
  'theuspants.com',
  'timejeans.net',
  'jenn-bra.com',
  'yourexsecret.com',
  'fluff-shoe.mycellas.com',
  'jenn-beauty.com'
]

const fullsessionDomains = [
  'thejennbras.com',
  'shapeezpant.com',
  'simplescalp.com',
  'frontguardiant.com',
  'clionabras.com',
  'iwing-bra.com',
  'shapeezpants.com',
  'leathershaper.com',
  'upwing-bras.com',
  'wingbras.com',
  'knitedbeanie.com',
  'laceupbras.com',
  'icesweater.com',
  'upwingsecret.com',
  'charm-bra.com',
  'myyouglegloves.com',
  'besweater.com',
  'lovelydora.us',
  'rubysecret.com',
  'frontsecret.com',
  'seamles-charm.com',
  'wavebras.com',
  'jadebras.com',
  'jadebra.com',
  'jadebra.us',
  'jade-secret.com',
  'youglegloves.com',
  'myfrontsecret.com',
  'charmingwave.com',
  'yougle-gloves.com',
  'wingbra.net',
  'theseamlesscharm.com',
  'ruby-secret.com',
  'seamcharm.com',
  'winterbeanie.com',
  'jadebra-usa.com',
  'iwingsbra.com',
  'warm-beanie.com',
  'knittedbeanie.com',
  'queenebra.com',
  'beaniecozy.com',
  'yourcecilia.com',
  'laceupbra.com',
  'knitted-beanie.com',
  'lolisecret.com',
  'vascaba.com',
  'myseamlessbra.com',
  'myseamlesscharm.com',
  'yourseamlessbra.com',
  'almazus.com',
  'rubyelegance.com',
  'upwingsbra.com',
  'buttonsbeanie.com',
  'famsock.com',
  'jadebra.us',
  'upwingbras.com',
  'upwing-bra.com',
  'lonisecret.com',
  'go-beanie.com',
  'aureliaus.com',
  'beanie-hat.com',
  'jadebra-usa.com',
  'iwingsbra.com',
  'wavesecret.us',
  'jade-bra.com',
  'wavesecret.com',
  'jade-bras.com',
  'floatbra.com',
  'floatbra.us',
  'wavelingerie.com',
  'seamless-charm.com',
  'brasforyou.us',
  'hiddencharm.us',
  'frontbrasecrets.com',
  'seamlesscharm.us',
  'charmbras.com',
  'myjennbra.com',
  'thejennbra.com',
  'jadesecret.com',
  'frontbra.olzey.com',
  'upwingbeauty.com',
  'upwinganhnh2412.onselless.com',
  'upwingbra.net',
  'lucianasecret.com',
  'carlacharm.com',
  'mycloverapparel.com',
  'missboutiques.com',
  'chloebras.com',
  'skyesecret.com',
  'starrysecret.com'
]

const bdlScriptDomains: Types.PlainObject = {
  'jennbra.bragoddess.com': 17,
  'upwingbra.bragoddess.com': 18,
  'jenn-bra.bragoddess.com': 19
}

const customGtmIds: Types.PlainObject = {
  'bramuse.com': 'GTM-K2Z9T9Z'
}

export function isCustomScriptDomains(domain: string) {
  return customScriptDomains.includes(domain)
}

export function isBDLScriptDomains(domain: string) {
  return [Object.getOwnPropertyNames(bdlScriptDomains).includes(domain), bdlScriptDomains[domain]]
}

export function isCustomGtmDomains(domain: string) {
  return [Object.getOwnPropertyNames(customGtmIds).includes(domain), customGtmIds[domain]]
}

export function isAlphaTestDomain(domain: string) {
  return fullsessionDomains.includes(domain)
}
