import { useEffect, useState } from 'react'

export function useAgents() {
  const [devide, setDevice] = useState('unknown')
  useEffect(() => {
    setDevice(getOperatingSystem())
  }, [])
  return devide
}

function getOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera

  // iOS detection (iPhone, iPad, iPod)
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return 'IOS'
  }
  // Android detection
  else if (/android/i.test(userAgent)) {
    return 'Android'
  }
  // macOS detection
  else if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
    return 'macOS'
  }
  // Default to 'unknown'
  return 'unknown'
}
