import { iconPath } from '@ui/helpers'
import { NextPage } from 'next'

import css from 'styled-jsx/css'

const PreviewExpiredPage: NextPage = () => {
  return (
    <>
      <div className="page preview-page-expired">
        <img className="logo" src={iconPath('selless_logo.svg')} alt="logo" />
        <div className="preview-page-expired__content">
          <img className="warning-img" src={iconPath('warning.svg')} alt="warning" />
          <h1>Preview session expired or invalid.</h1>
          <span className="description">Please renew the token to continue</span>
        </div>
      </div>
      <style jsx global>
        {style}
      </style>
    </>
  )
}

export default PreviewExpiredPage

const style = css.global`
  .preview-page-expired {
    color: #000000;
    padding: 64px;
    @media screen and (max-width: 768px) {
      padding: 32px 16px;
    }
    .logo {
      height: 48px;
      width: auto;
      @media screen and (max-width: 768px) {
        height: 32px;
      }
    }

    &__content {
      margin-top: 160px;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      @media screen and (max-width: 768px) {
        margin-top: 100px;
      }
      h1 {
        padding-top: 40px;
        font-weight: 600;
        font-size: 36px;
        line-height: 48px;
        @media screen and (max-width: 768px) {
          font-size: 24px;
          line-height: 32px;
          padding-top: 20px;
        }
      }
      .description {
        padding-top: 16px;
        color: #363636;
        font-size: 20px;
        line-height: 32px;
        @media screen and (max-width: 768px) {
          font-size: 16px;
          line-height: 24px;
          padding-top: 20px;
        }
      }
      .warning-img {
        height: 160px;
        width: auto;
        @media screen and (max-width: 768px) {
          height: 100px;
        }
      }
    }
  }
`
