import { imagePath } from '@ui/helpers'
import getConfig from 'next/config'

export function fillArray(length: number) {
  return Array.from({ length }, (_, i) => i + 1)
}

export function moneyConverter(money: string | number) {
  return parseFloat(money as string).toFixed(2)
}

export function cleanObject(object: Types.PlainObject, deep = true, removeZero = false) {
  const obj: Types.PlainObject = {}
  for (const key in object) {
    if (typeof object[key] === 'object' && !Array.isArray(object[key])) {
      obj[key] = deep ? cleanObject(object[key], true, removeZero) : object[key]
    } else if (object[key]) {
      obj[key] = object[key]
    } else if (object[key] === 0 && !removeZero) {
      obj[key] = 0
    }
  }

  return obj
}

export function dynamicSort(property: string) {
  let sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a: any, b: any) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function getTimeArr(secs: number): string[] {
  if (!secs) {
    return []
  }
  if (secs <= 60) {
    // return `00:${pad2num(secs)}`
    return [pad2num(secs)]
  }
  let minutes = Math.floor(secs / 60)
  const seconds = secs % 60
  if (minutes < 60) {
    // return `${pad2num(minutes)}:${pad2num(seconds)}`
    return [pad2num(minutes), pad2num(seconds)]
  }
  const hours = Math.floor(minutes / 60)
  minutes = minutes % 60

  // return `${pad2num(hours)}:${pad2num(minutes)}:${pad2num(seconds)}`
  return [pad2num(hours), pad2num(minutes), pad2num(seconds)]
}

export function pad2num(value: number | string) {
  if (Number(value) < 10) {
    return `0${value}`
  }
  return `${value}`
}

export function deepCloneWithJSON(data: any) {
  return JSON.parse(JSON.stringify(data))
}

export function deepCloneObject(inObject: Types.PlainObject): any {
  if (typeof inObject !== 'object' || inObject === null) {
    return inObject // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  const outObject = Array.isArray(inObject) ? [] : {}

  for (const key in inObject) {
    // @ts-ignore
    const value = inObject[key]

    // Recursively (deep) copy for nested objects, including arrays
    // @ts-ignore
    outObject[key] = deepCloneObject(value)
  }

  return outObject
}

export function getCurrency(value: string | number) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(+value)
}

export const getQueryString = (params: Types.PlainObject, ignoreParams?: string[]) =>
  '?' +
  Object.keys(params)
    .filter((key) => !ignoreParams?.includes(key))
    .map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    })
    .join('&')

export function getQueryParams(params: Types.PlainObject): string {
  return (
    '?' +
    Object.keys(params)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
      .join('&')
  )
}

export const toQueryParams = (params: Types.PlainObject) =>
  '?' +
  Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&')

export const isEmpty = (obj: any) => JSON.stringify(obj) === '{}'

export function parsePhone(phone: string) {
  let digitPhone = phone.replace(/\D/g, '')
  if (digitPhone.length === 11 && digitPhone.startsWith('1')) {
    digitPhone = digitPhone.substring(1)
  }
  const x = digitPhone.match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
  if (x !== null) {
    return (!x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')).trim()
  }
  return ''
}

export function displayUSPhoneFormatted(phone: string) {
  let digitPhone = phone.replace(/\D/g, '')
  if (digitPhone.length === 11 && digitPhone.startsWith('1')) {
    digitPhone = digitPhone.substring(1)
  }
  const x = digitPhone.match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
  if (x !== null) {
    return '+1 ' + (!x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')).trim()
  }
  return ''
}

const cdnBasePath = getConfig().publicRuntimeConfig.cdnBasePath
const shortCdnBasePath = cdnBasePath?.replace('https://', '') ?? ''

export const getImagesLink = (description: string) => {
  return (
    description
      ?.replace(/http:\/\//gm, 'https://')
      // .replace(/(<img([^>]*))( src=)/gm, '$1 alt="" data-src=')
      .replace(/src="([^'"]*)"/g, (_x, y: string) => {
        if (y.includes('.mp4')) return `src="${y}"`
        const image = y
          .replace(cdnBasePath, '')
          .replace(shortCdnBasePath, '')
          .split('/')
          .filter((z) => z)
          ?.join('/')
        y = y.includes('https://') ? y : `https://${y}`
        return `src="${imagePath(image)}"`
      })
      ?.match(/src="([^'"]*)/g)
      ?.map((x) => x.replace('src="', ''))
  )
}

export const to17TrackLink = (trackingNumber: string) => {
  return `https://t.17track.net/en#nums=${trackingNumber}`
}

// export const toUPSPLink = () => {
//   return `https://tools.usps.com/find-location.htm`
// }

export const removeSpecials = (str: string) => {
  return str.replace(/[^.a-zA-Z0-9 ]/g, '')
}

export const isPositiveRealNumber = (str: string) => {
  return str.match(/^\d*[.]?\d*$/g)
}
