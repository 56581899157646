// export function appendSubpath(subpath?: string | null) {
//   return `${subpath ? `/${subpath}` : ''}`
// }

export function getAvarageRated(reviews: S3Types.page_review[]) {
  if (reviews.length) {
    const totalRated = reviews.reduce((total, review) => total + (review.rating ?? 0), 0)
    return Math.ceil(totalRated / reviews.length)
  }

  return 0
}
