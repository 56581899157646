export function freeze() {
  document.documentElement.classList.add('is-clipped')
}

export function unFreeze() {
  document.documentElement.classList.remove('is-clipped')
}

export function getElementByXpath(xpath: string) {
  return document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null)
    .singleNodeValue as Element
}

export const convertTextareaToHTML = (html: string) => {
  return html.replace(/(?:\r\n|\r|\n)/g, '<br>')
}
