import Script from 'next/script'
import React from 'react'

interface IGtagScript {
  gaIds: string[]
}

export function GtagScript({ gaIds }: IGtagScript) {
  return (
    <React.Fragment>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${gaIds[0]}`} />
      <Script
        dangerouslySetInnerHTML={{
          __html: `  window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            ${gaIds.map((id) => `gtag('config', '${id}');`).join('')}`
        }}
      />
    </React.Fragment>
  )
}
