const singleton: { [key: string]: boolean } = {}

export const importScript = (url: string, retry = 10, retryDelay = 1000): Promise<any> => {
  const timer = (ms: number) => new Promise((res) => setTimeout(res, ms))

  const loading = async () => {
    const script = document.createElement('script')
    script.setAttribute('async', 'true')
    script.src = url
    document.body.appendChild(script)
    return new Promise((res) => {
      script.onload = () => {
        singleton[url] = true
        res(true)
      }
      script.onerror = () => {
        document.body.removeChild(script)
        res(false)
      }
    })
  }

  const tryToLoad = async (res: any) => {
    const success = await loading()
    if (success) return res(success) // ready !!!
    if (retry == 0) {
      console.error('Fail to load', url)
      return res(null) // timeout !!!
    }
    await timer(retryDelay)
    retry--
    tryToLoad(res)
  }

  return new Promise((res) => (singleton[url] ? res(true) : tryToLoad(res)))
}

export const getImportedScript = (
  checkFn: () => any,
  timeout = 30000,
  offset = 300
): Promise<any> => {
  let time = 0
  const timer = (ms: number) => new Promise((res) => setTimeout(res, ms))

  const checkExist = async (res: any) => {
    const obj = checkFn()
    if (obj) return res(obj) // ready !!!
    if (time > timeout) return res(null) // timeout !!!
    await timer(offset)
    time += offset
    checkExist(res)
  }

  return new Promise((res) => checkExist(res))
}
