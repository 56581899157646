import short from 'short-uuid'

const template = 'abcdefghijklmnopqrstuvwxyz0123456789'

export function genSid() {
  try {
    const translator = short(template)
    const newId = translator.generate()
    return newId
  } catch (error) {
    console.error('Error with gen sid', error)
    return `${Date.parse(new Date().toString())}`
  }
}

export function genUuid() {
  const newId = short().uuid()
  return newId
}
