export const localOrderIdKey = (slug?: string) =>
  slug ? `_local_${slug}_order_id` : '_local_order_id'
export const localOrderKey = (slug?: string) => (slug ? `_local_${slug}_order` : '_local_order')
export const localBuyNowOrderKey = (slug?: string) =>
  slug ? `_local_${slug}_buynow_order` : '_local_buynow_order'
export const localPurchasedOrderKey = (slug?: string) =>
  slug ? `_local_${slug}_purchased_order` : '_local_purchased_order'
export const localIsBuyNowKey = (slug?: string) =>
  slug ? `_local_${slug}_isbuynow` : '_local_isbuynow'
export const localIsPayingKey = (slug?: string) =>
  slug ? `_local_${slug}_ispaying` : '_local_ispaying'
export const localDiscountCodeKey = (slug?: string) =>
  slug ? `_local_${slug}_discount_code` : '_local_discount_code'
export const localSourceEventKey = (slug?: string) =>
  slug ? `_local_${slug}_source_event` : '_local_source_event'
export const localShippingAddressKey = '_local_shipping'
export const lastPLabelItem = '_local_last_p_label_item_'
export const isPayingWithPaypalKey = 'is_paying_paypal'
export const priceChangedVariantItemsKey = (slug?: string) =>
  slug ? `items_${slug}_changed_price` : 'items_changed_price'
export const sessionIdKey = '_local_session_id'
export const isPuchaseKey = (slug?: string) =>
  slug ? `_local_${slug}_is_puchase` : '_local_is_puchase'

export const stripePaymentConfirm = '_local_stripe_confirm'
export const localStripePaymentConfirmKey = (slug?: string) =>
  slug ? `_local_${slug}_stripe_confirm` : '_local_stripe_confirm'
export const localOtpOutKey = (slug?: string) => (slug ? `_local_${slug}_otpout` : '_local_otpout')
export const localpaymentTypeOrderKey = '_local_payment_type_order'

export const localIsCollectTax = '_local_is_collect_tax'
export const localVisitTimeKey = '_local_local_visit'
export const eventIdFbKey = 'event_id_fb'

export const localIsPaypalThankYouId = (slug?: string) =>
  slug ? `_local_is_paypal_${slug}_thank_you` : 'is_paypal_thank_you'

export const localOrderPaypalId = (slug?: string) =>
  slug ? `_local_${slug}_paypal_order_id` : '_local_paypal_order_id'
export const isMaxQuantityDiscount = 'is_max_quantity_discount'
export const isMaxQuantityDiscountProduct = 'is_max_quantity_discount_product'
export const isMaxItemNegative = 'is_max_item_negative'
export const expiredDiscountKey = 'is_expired_discount'
export const tokenBrKey = 'token_br'
export const minPriceThankyou = 'min_price_thankyou'

export const versionCookie = [
  'v13',
  'v14',
  'v141',
  'v142',
  'br1',
  'vx141',
  'v144',
  'e144',
  'v146',
  'a144',
  'c144',
  'a145',
  'b144',
  'ba10', // Add checked mark for verified buyer + Add checkmark on selection
  'ba11',
  'ba23',
  'bb23',
  'p144', // policy
  'd144',
  'o144',
  'g144',
  'r144',
  'l144',
  'm144',
  'i144',
  'x144', //base new
  'z144', //base new
  'k144', //base new
  'f144', //base new
  'usp1', //usp
  'usp2',
  'usp3',
  'usp4',
  'vcdn3',
  'acdn3',
  'vap1',
  'gp01a',
  'gp01b',
  'gp02',
  'vlz1',
  'vpo1',
  'vpl1',
  'vna2',
  'vli1'
]

export const listVersionUsp = ['usp1', 'usp2', 'usp3', 'usp4', 'p144', 'd144', 'v144']
export const gaExcludes = ['rosiedelight.com', 'maracharm.com']
export type showValueCD = 'NONE' | 'POPUP' | 'TEXT'
