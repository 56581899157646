import { useLocalSessionId, useRefDep } from '@libs/client'
import { axonTrack, imagePath } from '@ui/helpers'
import { useCallback } from 'react'
import { useSetting } from './useSetting'

export const propertiesText = (properties?: S3Types.item_properties) =>
  Object.values(properties ?? {}).join(' / ')

const productLabel = (sellpageSetting: S3Types.page_setting | null) =>
  sellpageSetting?.settings?.general?.title?.split('-')?.[0]?.trim() ?? ''
// eslint-disable-next-line react-hooks/rules-of-hooks

export const useAxon = () => {
  const [sellpageSetting] = useSetting('sellpage')
  const [localSessionId] = useLocalSessionId()
  const sessionIdRef = useRefDep(localSessionId)
  const sellpageSettingRef = useRefDep(sellpageSetting)
  const eventInfo = (items: Types.OrderItem[] = [], amount = 0): Types.AxonEventData => {
    return {
      items: items.map((item) => {
        return {
          item_id: sellpageSettingRef.current?.settings.general.pbase_id || '',
          item_name: sellpageSettingRef.current?.settings.general.title ?? '',
          item_brand: productLabel(sellpageSettingRef.current),
          item_category: 'Pants',
          image_url: imagePath(item.imageUrl || ''),
          item_variant: propertiesText(item.properties),
          price: item.price ?? 0,
          quantity: item.quantity
        }
      }),
      value: amount,
      currency: 'USD'
    }
  }

  const axonTrackPageView = useCallback(() => {
    axonTrack('page_view')
  }, [sellpageSettingRef])

  const axonTrackViewItem = useCallback(
    (variantSelected: S3Types.page_variants_item | null) => {
      axonTrack('view_item', {
        items: [
          {
            item_id: sellpageSettingRef.current?.settings.general.pbase_id || '',
            item_name: sellpageSettingRef.current?.settings.general.title ?? '',
            item_brand: productLabel(sellpageSettingRef.current),
            item_category: 'Pants',
            price: variantSelected?.default_price || 0,
            item_variant: variantSelected?.id || '',
            image_url: imagePath(variantSelected?.logo || '')
          }
        ]
      })
    },
    [sellpageSettingRef]
  )

  const axonTrackAddToCart = useCallback(
    (selectedVariant?: S3Types.page_variants_item | null, quantity = 0) => {
      axonTrack('add_to_cart', {
        items: [
          {
            item_id: sellpageSettingRef.current?.settings.general.pbase_id || '',
            item_name: sellpageSettingRef.current?.settings.general.title ?? '',
            item_brand: productLabel(sellpageSettingRef.current),
            item_category: 'Pants',
            image_url: imagePath(selectedVariant?.logo || ''),
            item_variant: propertiesText(selectedVariant?.properties),
            price: selectedVariant?.default_price ?? 0,
            quantity
          }
        ],
        value: (selectedVariant?.default_price ?? 0) * quantity
      })
    },
    [sellpageSettingRef]
  )

  const axonTrackRemoveFromCart = useCallback(
    (removedVariant?: Types.OrderItem | null, quantity = 0) => {
      axonTrack('remove_from_cart', {
        items: [
          {
            item_id: sellpageSettingRef.current?.settings.general.pbase_id || '',
            item_name: sellpageSettingRef.current?.settings.general.title ?? '',
            item_brand: productLabel(sellpageSettingRef.current),
            item_variant: propertiesText(removedVariant?.properties),
            price: removedVariant?.price ?? 0,
            image_url: imagePath(removedVariant?.imageUrl || ''),
            item_category: 'Pants',
            quantity
          }
        ],
        value: (removedVariant?.price ?? 0) * quantity
      })
    },
    [sellpageSettingRef]
  )

  const axonTrackBeginCheckout = useCallback(
    (items: Types.OrderItem[] = [], items_amount = 0) => {
      axonTrack('begin_checkout', eventInfo(items, items_amount))
    },
    [sellpageSettingRef]
  )

  const axonTrackPurchase = useCallback(
    (order?: Types.Order) => {
      axonTrack('purchase', {
        ...eventInfo(order?.items, (order?.items_amount ?? 0) - (order?.discount_amount ?? 0)),
        tax: order?.tax_amount ?? 0,
        shipping: order?.shipping_amount ?? 0,
        transaction_id: order?.id,
        user_id: sessionIdRef.current || ''
      })
    },
    [sellpageSettingRef]
  )

  return {
    axonTrackPageView,
    axonTrackViewItem,
    axonTrackAddToCart,
    axonTrackRemoveFromCart,
    axonTrackBeginCheckout,
    axonTrackPurchase
  }
}
