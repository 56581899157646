import getConfig from 'next/config'

// const fullSessionDomains = [
//   'jenbra.us',
//   'starryclover.com',
//   'cloverdresser.com',
//   'iposhbags.com',
//   'rubylavish.com',
//   'thejennbra.com',
//   'thejennbras.com',
//   'upwingbras.com',
//   'wingbras.com',
//   'floriess.com',
//   'poshbras.com',
//   'emoutlets.com',
//   'jenngirl.com',
//   'mychloebra.com',
//   'skyesecret.com',
//   'reinesecret.com',
//   'theponashbra.com',
//   'cherylgirl.com'
// ]

const fraudDomains = [
  // nguyenxuanthang15101994@gmail.com
  'braseamless.net',
  'lacebrashop.onselless.com',
  'fhfhgvf.onselless.com',
  'avabra.net',
  'miabra.net',
  // dinhvu500@gmail.com
  'emmabra.com',
  'miajeans.com',
  'oliviabra.com',
  'vu674.onselless.com',
  // doncorp.vietnam@gmail.com
  'discount.vitodecor.com',
  'linesofa.vitodecor.com',
  'bra.jennboutique.com',
  'vitodecor.onselless.com',
  'jennboutique.com',
  'jean1.onselless.com',
  'jean.jennboutique.com',
  'jennyboutique.net',
  'discount.jennboutique.com',
  'jennshop.com',
  // tuandac.nguyen.ftu@gmail.com
  'victorybra.com',
  'jenniferbra.com',
  'bravictory.com',
  'janebra.com',
  'victoryjeans.com',
  'jeancloset.com',
  'avacloset.com',
  'test12.onselless.com',
  'ellabra.com'
]

const isFullSessionPublishers: string | undefined = getConfig().publicRuntimeConfig
  .logRocketFullPublisher

export function isFullSessionDomain(fullSessionDomains: string[]) {
  return (
    process.browser &&
    (fullSessionDomains || []).some((x: string) => window.location.href.includes(x))
  )
}

export function isFraudDomain() {
  return process.browser && fraudDomains.includes(window.location.hostname)
}

export function isFullSessionPublisher(sellpageId = '') {
  try {
    const publisherIds = isFullSessionPublishers?.split(',')
    return sellpageId && publisherIds?.includes(sellpageId)
  } catch (err: any) {
    console.error(err)
    return false
  }
}
