export enum ppage_setting_template {
  default = 'default',
  p10n = 'p10n'
}

export enum ppage_settings_shipping_type {
  AMOUNT = 'AMOUNT',
  QUANTITY = 'QUANTITY'
}

export enum page_settings_configurations_display {
  BUTTON = 'Button',
  DROPDOWN = 'Dropdown'
}

export enum page_settings_shipping_type {
  AMOUNT = 'AMOUNT',
  QUANTITY = 'QUANTITY'
}

export enum paygate_type {
  CREDITCARD = 'CREDITCARD',
  PAYPALEX = 'PAYPALEX'
}

export enum paygate_adapter {
  BRAINTREE = 'BRAINTREE',
  STRIPE = 'STRIPE',
  PAYPALEX = 'PAYPALEX',
  BLUESNAP = 'BLUESNAP'
}

export enum paygate_supportcard {
  VISA = 'VISA',
  MASTER = 'MASTER',
  DISCOVER = 'DISCOVER',
  AMEX = 'AMEX',
  JCB = 'JCB',
  UNIONPAY = 'UNIONPAY',
  DINERS_CLUB = 'DINERS_CLUB'
}

export enum product_family {
  DEFAULT = 'DEFAULT',
  P10N = 'P10N'
}

export enum promotion_type {
  SHIP = 'SHIP',
  PERCENT = 'PERCENT',
  FIXED = 'FIXED'
}

export enum promotion_apply {
  GLOBAL = 'GLOBAL',
  COLLECTION = 'COLLECTION',
  PPAGE = 'PPAGE',
  TAGS = 'TAGS'
}

export enum store_setting_template {
  default = 'default',
  p10n = 'p10n'
}

export enum store_type {
  SELLPAGE = 'SELLPAGE',
  MINISTORE = 'MINISTORE'
}

export enum method_type {
  CREDITCARD = 'CREDITCARD',
  PAYPALEX = 'PAYPALEX'
}

export enum category_type {
  clearance = 'clearance',
  'new-arrivals' = 'new-arrivals',
  bestsellers = 'bestsellers',
  'pre-order' = 'pre-order'
}
