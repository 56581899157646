import React, { FC, useEffect } from 'react'
import {
  invertColor,
  isBDLScriptDomains,
  isCustomGtmDomains,
  isCustomScriptDomains
} from '@ui/helpers'
import { NotiProvider } from '@notify/NotiContext'
import { useSetting } from '@ui/hooks'

const DefaultLayout: FC = ({ children }) => {
  const [storeSetting] = useSetting('store')
  const [sellpageSetting] = useSetting('sellpage')
  const colors = sellpageSetting?.settings?.colors

  const domain = storeSetting?.general?.domain ?? ''
  const [showBdlScript] = isBDLScriptDomains(domain)
  const [showCustomGtm, gtmId] = isCustomGtmDomains(domain)
  const showCustomScript = isCustomScriptDomains(domain)

  useEffect(() => {
    const root = document.documentElement

    const background = colors?.background
    if (!!background && background?.toLowerCase() !== '#f7f7f7') {
      root.style.setProperty('--background', background)
      root.style.setProperty('--background-invert', invertColor(background))
    }

    const primary = colors?.primary
    if (!!primary && primary?.toLowerCase() !== '#1b1b1b') {
      root.style.setProperty('--primary', primary)
      root.style.setProperty('--primary-invert', invertColor(primary))
    }

    const secondary = colors?.secondary
    if (!!secondary && secondary?.toLowerCase() !== '#ff4343') {
      root.style.setProperty('--secondary', secondary)
      root.style.setProperty('--secondary-invert', invertColor(secondary))
    }

    const textColor = colors?.text
    if (!!textColor && textColor?.toLowerCase() !== '#1b1b1b') {
      root.style.setProperty('--text', textColor)
      root.style.setProperty('--text-invert', invertColor(textColor))
    }

    const logoWidth = `${storeSetting?.general?.logo?.width ?? 210}px`
    if (!!logoWidth && logoWidth?.toLowerCase() !== '210px') {
      root.style.setProperty('--logo-width', logoWidth)
    }
  }, [])
  return (
    <NotiProvider>
      <>
        {showBdlScript && (
          <div>
            <noscript>
              <iframe
                src="https://www.googletagmanager.com/ns.html?id=GTM-NB2CMDC"
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
                title="bdlScript"
              ></iframe>
            </noscript>
          </div>
        )}
        {showCustomScript && (
          <div>
            <noscript>
              <iframe
                src="https://www.googletagmanager.com/ns.html?id=GTM-NF8NBXK"
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
                title="customScript"
              ></iframe>
            </noscript>
          </div>
        )}
        {showCustomGtm && (
          <div>
            <noscript>
              <iframe
                src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
                title="customGtm"
              ></iframe>
            </noscript>
          </div>
        )}
        {children}
      </>
    </NotiProvider>
  )
}

export default DefaultLayout
