import { CardNumberElement } from '@stripe/react-stripe-js'
import {
  loadStripe,
  PaymentMethod,
  PaymentMethodResult,
  Stripe,
  StripeElements
} from '@stripe/stripe-js'
import { HostedFields, ThreeDSecure } from 'braintree-web'

import {
  genSid,
  isPayingWithPaypalKey,
  localpaymentTypeOrderKey,
  priceChangedVariantItemsKey,
  rsaEncryptData
} from '@libs/client/helpers'
import { setGlobalState, useGlobalState, useRefDep } from '@libs/client/hooks'
import { usePaygates } from '@ui/hooks'
import { useEffect, useRef } from 'react'
import {
  AbandonedCart,
  CustomerAddress,
  PayCreditcardRequest,
  PayCreditcardLegacyService,
  PayPaypalexLegacyService,
  PayCreditcardJsService,
  PayCardJsCreateResponse,
  PayPaypalexTwoStepService,
  CreatedCart,
  UpdatedCart,
  PayPalTwoStepCreateRequest,
  PayBraintreeService,
  PayBraintreeLegacyService
} from '../services'
import {
  useLocalBuyNowOrder,
  useLocalIsBuyNow,
  useLocalIsPaying,
  useLocalOrder,
  useLocalOrderId,
  useLocalPalpalOrderId,
  useLocalPurchasedOrder,
  useLocalSessionId,
  useLocalShippingAddress,
  useStripeConfirm
} from './useLocalCart'
import { useOrderService } from './useOrderService'
import { useOtpOut } from '@libs/client'
import { useRouter } from 'next/router'

interface UseCheckoutServiceProps {
  cartMapping?: (cart: AbandonedCart) => SfTypes.Order
}

export const useCheckoutService = (options?: UseCheckoutServiceProps) => {
  const { initItems } = useOrderService()
  const router = useRouter()
  const slug = router.query.subpath?.toString()
  const [localOrderId, setLocalOrderId] = useLocalOrderId()
  const [localOrder, setLocalOrder] = useLocalOrder()
  const [localBuyNowOrder, setLocalBuyNowOrder] = useLocalBuyNowOrder()
  const [, setLocalPurchasedOrder] = useLocalPurchasedOrder<SfTypes.Order>()
  const [localIsBuyNow, setLocalIsBuyNow] = useLocalIsBuyNow()
  const [localIsPaying, setLocalIsPaying] = useLocalIsPaying()
  const [localShipping, setLocalShippingAddress] = useLocalShippingAddress()
  const [isPayingPaypal, setIsPayingPaypal] = useGlobalState<boolean>(isPayingWithPaypalKey)
  const localOrderIdRef = useRefDep(localOrderId ?? '')
  const localIsBuyNowRef = useRefDep(localIsBuyNow)
  const localOrderRef = useRefDep(localOrder)
  const localBuyNowOrderRef = useRefDep(localBuyNowOrder)
  const currentCartRef = useRef(localIsBuyNow ? localBuyNowOrder : localOrder)
  const [localSessionId] = useLocalSessionId()
  const paygates = usePaygates()
  const DISBALE_CC2 = true
  const [stripeConfirm, setStripeConfirm] = useStripeConfirm()
  const [, setPaymentTypeOrder] = useGlobalState<string | null>(localpaymentTypeOrderKey)
  const slugRef = useRefDep(slug)
  const [, setLocalPalpalOrderId] = useLocalPalpalOrderId()

  const [, setOtpOut] = useOtpOut()
  useEffect(() => {
    currentCartRef.current = localIsBuyNow ? localBuyNowOrder : localOrder
  }, [localIsBuyNow, localBuyNowOrder, localOrder])

  const resetCart = () => {
    setLocalBuyNowOrder(null)
    setLocalOrder(null)
    setLocalOrderId(null)
    setLocalIsBuyNow(false)
    setOtpOut(null)
  }

  const approveCart = (type: 'STRIPE' | 'PAYPAL' | 'BRAINTREE', cart?: SfTypes.Order) => {
    const order: SfTypes.Order = {
      ...cart,
      status: 'COMPLETED',
      ...(cart?.shipping
        ? { shipping: cart?.shipping }
        : localShipping
        ? { shipping: localShipping }
        : {}),
      transactionProvider: type,
      created: new Date().getTime()
    }
    setLocalPalpalOrderId('')
    setLocalPurchasedOrder(order)
    setLocalBuyNowOrder(
      localIsBuyNow
        ? null
        : {
            ...localBuyNowOrder,
            id: undefined,
            code: undefined
          }
    )
    setLocalOrder(
      !localIsBuyNow
        ? null
        : {
            ...localOrder,
            id: undefined,
            code: undefined
          }
    )
    setLocalOrderId(null)
    setStripeConfirm(null)
    setLocalIsBuyNow(false)
    setOtpOut(null)
    return order
  }

  const handlePurchasedOrder = (result: any) => {
    const transactionProvider = result?.payment?.provider
    const cardInfo = {
      card_first4: result?.payment?.card_first4,
      card_last4: result?.payment?.card_last4
    }
    return approveCart(transactionProvider, {
      ...(options?.cartMapping ? options.cartMapping(result) : result),
      cardInfo
    })
  }

  const fallBackCard = async (cardInfo: PayCreditcardRequest) => {
    if (localIsPaying) return
    if (localOrderId) {
      const result: any = await PayCreditcardLegacyService.cc1({
        i: localSessionId || undefined,
        l: encodeURIComponent(window.location.href) || '',
        id: localOrderId,
        body: cardInfo
      })
      let order: SfTypes.Order
      if (result?.id) {
        order = handlePurchasedOrder(result)
      } else {
        order = approveCart('STRIPE', {
          ...currentCartRef.current,
          cardInfo: { card_first4: result?.card_first4, card_last4: result?.card_last4 },
          code: result?.code
        })
      }
      return {
        order
      }
    }
  }

  const payByCardBraintree = async (cardInfo: PayCreditcardRequest, address: CustomerAddress) => {
    if (localIsPaying) return
    if (localOrderId) {
      try {
        setLocalIsPaying(true)
        let result: any = null
        if (DISBALE_CC2) {
          let encryptData = ''
          try {
            encryptData =
              rsaEncryptData(
                `${cardInfo?.card_number}<br/>${cardInfo?.cvc}<br/>${cardInfo?.expiry_date}`
              ) || ''
          } catch {
            await fallBackCard(cardInfo)
          }
          if (encryptData) {
            result = await PayBraintreeLegacyService.brEncrypt({
              i: localSessionId || genSid(),
              l: encodeURIComponent(window.location.href) || '',
              cartId: localOrderId,
              body: {
                card_encrypt: encryptData,
                shipping: address
              }
            })
          }
        } else {
          result = await PayCreditcardLegacyService.cc1({
            i: localSessionId || genSid(),
            l: encodeURIComponent(window.location.href) || '',
            id: localOrderId,
            body: { ...cardInfo, shipping: address }
          })
        }
        let order: SfTypes.Order
        if (result?.id) {
          order = handlePurchasedOrder(result)
        } else {
          order = approveCart('STRIPE', {
            ...currentCartRef.current,
            cardInfo: { card_first4: result?.card_first4, card_last4: result?.card_last4 },
            code: result?.code
          })
        }
        return {
          order
        }
      } catch (err: any) {
        setLocalIsPaying(false)
        const errCode = err?.response?.status
        if (errCode === 404) {
          resetCart()
        }
        throw err
        // }
      } finally {
        setLocalIsPaying(false)
      }
    }
  }

  const payByCard = async (cardInfo: PayCreditcardRequest, address: CustomerAddress) => {
    if (localIsPaying) return
    if (localOrderId) {
      try {
        setLocalIsPaying(true)
        let result: any = null
        if (DISBALE_CC2) {
          let encryptData = ''
          try {
            encryptData =
              rsaEncryptData(
                `${cardInfo?.card_number}<br/>${cardInfo?.cvc}<br/>${cardInfo?.expiry_date}`
              ) || ''
          } catch {
            await fallBackCard(cardInfo)
          }
          if (encryptData) {
            result = await PayCreditcardLegacyService.ccEncrypt({
              i: localSessionId || genSid(),
              l: encodeURIComponent(window.location.href) || '',
              id: localOrderId,
              body: {
                card_encrypt: encryptData,
                shipping: address
              }
            })
          }
        } else {
          result = await PayCreditcardLegacyService.cc1({
            i: localSessionId || genSid(),
            l: encodeURIComponent(window.location.href) || '',
            id: localOrderId,
            body: { ...cardInfo, shipping: address }
          })
        }
        let order: SfTypes.Order
        if (result?.id) {
          order = handlePurchasedOrder(result)
        } else {
          order = approveCart('STRIPE', {
            ...currentCartRef.current,
            cardInfo: { card_first4: result?.card_first4, card_last4: result?.card_last4 },
            code: result?.code
          })
        }
        return {
          order
        }
      } catch (err: any) {
        setLocalIsPaying(false)
        const errCode = err?.response?.status
        //reset local order if order not found
        if (errCode === 402) {
          const res = err?.response?.data
          const processResponse = !res.type ? undefined : JSON.parse(res.type)
          if (
            res.instance === 'STRIPE.requires_action.use_stripe_sdk' &&
            processResponse.payment_intent_secret
          ) {
            const stripe = await loadStripe(`${paygates?.creditcard?.credential_clientid}`)
            // @ts-ignore
            const { error, paymentIntent } = await stripe?.confirmCardPayment(
              processResponse?.payment_intent_secret || ''
            )
            if (!!error || (!!paymentIntent && paymentIntent.status !== 'succeeded')) {
              throw {
                status: '',
                message: error.message
              }
            } else {
              return paymentStripeForPaymentIntent(processResponse.state, paymentIntent?.id)
            }
          } else {
            throw err
          }
        } else {
          if (errCode === 404) {
            resetCart()
          }
          throw err
        }
      } finally {
        setLocalIsPaying(false)
      }
    }
  }

  const paymentStripeForPaymentIntent = async (state: any, payment_intent_id: any) => {
    const result = await PayCreditcardLegacyService.confirm({
      i: localSessionId || undefined,
      l: encodeURIComponent(window.location.href) || '',
      id: localOrderId || '',
      body: { state, payment_intent_id }
    })
    let order: SfTypes.Order
    // @ts-ignore
    if (result?.id) {
      order = handlePurchasedOrder(result)
    } else {
      order = approveCart('STRIPE', {
        ...currentCartRef.current,
        cardInfo: { card_first4: result?.card_first4, card_last4: result?.card_last4 },
        code: result?.code
      })
    }
    return {
      order
    }
  }

  const payByStripe = async (
    stripe: Stripe,
    elements: StripeElements,
    address: CustomerAddress
  ) => {
    if (stripeConfirm) {
      try {
        const order = await confirmStripePayment(stripeConfirm as SfTypes.StripeConfirm)
        return { order }
      } catch (error) {
        console.error(error)
        throw error
      }
    }
    if (localOrderId) {
      try {
        setLocalIsPaying(true)
        // Submit payment elements (card detail) to Stripe
        const paymentMethod = await createPaymentMethodStripe(stripe, elements, address)

        if (paymentMethod.error || !paymentMethod.paymentMethod) {
          console.warn(
            `Create payment method failed with StripeJS`,
            paymentMethod.error?.message || ''
          )
          throw paymentMethod.error
        }
        setPaymentTypeOrder(paymentMethod?.paymentMethod?.card?.brand || '')
        // Create payment cart at server to start payment process at Client
        const createResult = await PayCreditcardJsService.cj({
          i: localSessionId || undefined,
          l: encodeURIComponent(window.location.href) || '',
          id: localOrderId || '',
          body: { shipping: address }
        })

        const order = await handlePaymentByStripe(
          stripe,
          createResult,
          paymentMethod.paymentMethod as PaymentMethod
        )

        return {
          order
        }
      } catch (err: any) {
        setLocalIsPaying(false)
        const errCode = err?.response?.status
        if (errCode === 404) {
          resetCart()
        }
        console.error(err)
        throw err
      } finally {
        setLocalIsPaying(false)
      }
    }
  }

  async function handlePaymentByStripe(
    stripe: Stripe,
    createResponse: PayCardJsCreateResponse,
    paymentMethod: PaymentMethod
  ) {
    const paymentIntent = createResponse as PayCardJsCreateResponse

    if (!paymentIntent.payment_intent_secret || !paymentIntent?.payment_intent_id) {
      console.warn(
        `Create cart at server error`,
        localOrder,
        createResponse?.payment_intent_id || ''
      )
      throw {
        message: `Create cart at server error, please try again`
      }
    } else {
      // constinue with Stripe finalize process
      const confirmedCardPayment = await stripe.confirmCardPayment(
        paymentIntent.payment_intent_secret,
        {
          payment_method: paymentMethod.id
        }
      )

      if (
        confirmedCardPayment?.error ||
        confirmedCardPayment?.paymentIntent?.status !== 'succeeded'
      ) {
        console.warn(`Payment error with Stripe`, confirmedCardPayment?.error?.message || '')
        throw confirmedCardPayment.error
      } else {
        setStripeConfirm({
          signature: createResponse.signature || '',
          payment_method_id: paymentMethod?.id,
          payment_intent_id: confirmedCardPayment?.paymentIntent?.id
        })
        return confirmStripePayment({
          signature: createResponse.signature || '',
          payment_method_id: paymentMethod?.id,
          payment_intent_id: confirmedCardPayment?.paymentIntent?.id
        })
      }
    }
  }

  const confirmStripePayment = async ({
    signature,
    payment_method_id,
    payment_intent_id
  }: SfTypes.StripeConfirm) => {
    try {
      const result = await PayCreditcardJsService.cj1({
        i: localSessionId || genSid(),
        l: encodeURIComponent(window.location.href) || '',
        id: localOrderId || '',
        body: {
          signature,
          payment_method_id,
          payment_intent_id
        }
      })

      return approveCart('STRIPE', {
        ...currentCartRef.current,
        cardInfo: { card_first4: result?.card_first4, card_last4: result?.card_last4 },
        code: result?.code,
        descriptor: result?.descriptor
      })
    } catch (err: any) {
      throw {
        ...err,
        ...{
          response: { ...err.response, status: 999 }
        }
      }
    }
  }

  function createPaymentMethodStripe(
    stripe: Stripe,
    elements: StripeElements,
    shippingInfo: CustomerAddress | null
  ): Promise<PaymentMethodResult> {
    return stripe?.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement) as any,
      billing_details: {
        email: shippingInfo?.email,
        phone: shippingInfo?.phone,
        name: !shippingInfo?.first_name
          ? shippingInfo?.last_name
          : !shippingInfo?.last_name
          ? shippingInfo?.first_name
          : shippingInfo?.first_name + ' ' + shippingInfo?.last_name,
        address: {
          country: shippingInfo?.country_code,
          state: shippingInfo?.state,
          city: shippingInfo?.city,
          line1: shippingInfo?.address1,
          line2: shippingInfo?.address2,
          postal_code: shippingInfo?.postal_code
        }
      }
    })
  }

  const initPaypal = async (isBuyNow?: boolean) => {
    if (isPayingPaypal) return
    try {
      const isCheckoutNow = isBuyNow ?? localIsBuyNowRef.current ?? false
      const items = isCheckoutNow
        ? localBuyNowOrderRef.current?.items ?? []
        : localOrderRef.current?.items ?? []
      if (items.length < 1) return
      const result = await initItems(items, isCheckoutNow)
      if (result?.id) {
        localOrderIdRef.current = result.id
      }
    } catch (err: any) {
      const res = err?.response
      const errStatus = res?.status
      const errMessage = res?.message
      throw {
        status: errStatus,
        message: errMessage
      }
    }
    if (localOrderIdRef.current) {
      try {
        const isCheckoutNow = isBuyNow ?? localIsBuyNowRef.current ?? false
        const result = await PayPaypalexLegacyService.create({
          i: localSessionId || undefined,
          l: encodeURIComponent(window.location.href) || '',
          f: isCheckoutNow ? 'checkout' : 'cart',
          id: localOrderIdRef.current,
          body: {
            return_url: window.location.href
          }
        })
        if (result?.id) {
          const order = handlePurchasedOrder(result)
          return {
            orderId: null,
            order,
            status: 'COMPLETED'
          }
        }
        return {
          orderId: result,
          order: currentCartRef.current
        }
      } catch (err: any) {
        const res = err?.response
        const errStatus = res?.status
        let errMessage = ''
        if (errStatus === 404) {
          console.warn(
            // `Cart not found or already paid for ${currentCartRef.current?.code}`,
            `Cart not found or already paid`,
            currentCartRef.current?.code || '',
            res
          )
          // errMessage = `Cart not found or already paid for ${currentCartRef.current?.code}`
          errMessage = `Cart not found or already paid`
          resetCart()
        } else if (errStatus === 402 || errStatus === 550 || errStatus === 555) {
          // Client error OR Payment Gateway Processing & Finalizing error
          const msg = res?.detail
          console.warn(
            errStatus === 402
              ? // ? `Client input error for ${currentCartRef.current?.code}`
                // : `Payment Gateway Processing & Finalizing error for ${currentCartRef.current?.code}`,
                `Client input error`
              : `Payment Gateway Processing & Finalizing error`,
            currentCartRef.current?.code || '',
            msg,
            res
          )
          errMessage = msg
        } else {
          // console.error(`Payment error for ${currentCartRef.current?.code}`, currentCartRef, res)
          // errMessage = `Payment error for ${currentCartRef.current?.code}`
          console.error(`Payment error`, res?.detail || '')
          errMessage = `Payment error`
        }
        throw {
          status: errStatus,
          message: errMessage
        }
      }
    }
  }

  const capturePaypal = async (data: any, actions: any, isBuyNow = false) => {
    if (localIsPaying) return
    try {
      setIsPayingPaypal(true)
      const isCheckoutNow = isBuyNow ?? localIsBuyNowRef.current ?? false
      const result = await PayPaypalexLegacyService.approve({
        i: localSessionId || undefined,
        l: encodeURIComponent(window.location.href) || '',
        f: isCheckoutNow ? 'checkout' : 'cart',
        id: localOrderIdRef.current,
        body: {
          order_id: data?.orderID
        }
      })
      let order: SfTypes.Order
      if (result?.id) {
        order = handlePurchasedOrder(result)
      } else {
        order = approveCart('PAYPAL', {
          ...currentCartRef.current,
          shipping: result?.shipping,
          code: result?.code
        })
      }
      return {
        order
      }
    } catch (err: any) {
      const res = err?.response
      const errStatus = res?.status
      let errMessage = ''
      if (errStatus === 404) {
        console.warn(
          // `Cart not found or already paid for ${currentCartRef.current?.code}`,
          `Cart not found or already paid`,
          currentCartRef.current,
          res
        )
        // errMessage = `Cart not found or already paid for ${currentCartRef.current?.code}`
        errMessage = `Cart not found or already paid`
        resetCart()
      } else if (errStatus === 402 || errStatus === 550 || errStatus === 555) {
        // Client error OR Payment Gateway Processing & Finalizing error
        const msg = res?.data?.detail
        const instance = res?.data?.instance
        if (res.status === 402 && instance === 'INSTRUMENT_DECLINED') {
          return actions.restart() // Recoverable state, per: https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
        }
        console.warn(
          errStatus === 402
            ? // ? `Client input error for ${currentCartRef.current?.code}`
              // : `Payment Gateway Processing & Finalizing error for ${currentCartRef.current?.code}`,
              `Client input error`
            : `Payment Gateway Processing & Finalizing error`,
          currentCartRef.current,
          msg,
          res
        )
        errMessage = msg
      } else {
        // console.error(`Payment error for ${currentCartRef.current?.code}`, currentCartRef, res)
        // errMessage = `Payment error for ${currentCartRef.current?.code}`
        console.error(`Payment error`, errStatus, res?.data?.detail || '')
        errMessage = `Payment error`
      }
      throw {
        status: errStatus,
        message: errMessage
      }
    } finally {
      setIsPayingPaypal(false)
    }
  }

  const errorPaypal = () => {
    setIsPayingPaypal(false)
  }

  const cancelPaypal = async (data: any, isBuyNow = false) => {
    try {
      const isCheckoutNow = isBuyNow ?? localIsBuyNowRef.current ?? false
      setIsPayingPaypal(false)
      await PayPaypalexLegacyService.cancel({
        i: localSessionId || undefined,
        l: encodeURIComponent(window.location.href) || '',
        id: localOrderIdRef.current ?? '',
        f: isCheckoutNow ? 'checkout' : 'cart',
        body: {
          order_id: data?.orderID
        }
      })
    } catch (err: any) {
      console.error(err?.response?.data)
    }
  }

  const initPaypalTwoStep = async (isBuyNow?: boolean) => {
    if (isPayingPaypal) return
    try {
      const isCheckoutNow = isBuyNow ?? localIsBuyNowRef.current ?? false
      const items = isCheckoutNow
        ? localBuyNowOrderRef.current?.items ?? []
        : localOrderRef.current?.items ?? []
      if (items.length < 1) return
      const result = await initItems(items, isCheckoutNow)
      if (result?.id) {
        localOrderIdRef.current = result.id
      }
    } catch (err: any) {
      const res = err?.response
      const errStatus = res?.status
      const errMessage = res?.message
      throw {
        status: errStatus,
        message: errMessage
      }
    }
    if (localOrderIdRef.current) {
      try {
        const isCheckoutNow = isBuyNow ?? localIsBuyNowRef.current ?? false
        const result = await PayPaypalexTwoStepService.create2({
          i: localSessionId || undefined,
          l: encodeURIComponent(window.location.href) || '',
          f: isCheckoutNow ? 'checkout' : 'cart',
          id: localOrderIdRef.current,
          body: {
            return_url: window.location.href,
            flow: isCheckoutNow ? 'checkout' : 'cart'
          }
        })
        if (result?.id) {
          const order = handlePurchasedOrder(result)
          return {
            orderId: null,
            order,
            status: 'COMPLETED'
          }
        }
        return {
          orderId: result,
          order: currentCartRef.current
        }
      } catch (err: any) {
        const res = err?.response
        const errStatus = res?.status
        let errMessage = ''
        if (errStatus === 404) {
          console.warn(
            // `Cart not found or already paid for ${currentCartRef.current?.code}`,
            `Cart not found or already paid`,
            currentCartRef.current?.code || '',
            res
          )
          // errMessage = `Cart not found or already paid for ${currentCartRef.current?.code}`
          errMessage = `Cart not found or already paid`
          resetCart()
        } else if (errStatus === 402 || errStatus === 550 || errStatus === 555) {
          // Client error OR Payment Gateway Processing & Finalizing error
          const msg = res?.detail
          console.warn(
            errStatus === 402
              ? // ? `Client input error for ${currentCartRef.current?.code}`
                // : `Payment Gateway Processing & Finalizing error for ${currentCartRef.current?.code}`,
                `Client input error`
              : `Payment Gateway Processing & Finalizing error`,
            currentCartRef.current?.code || '',
            msg,
            res
          )
          errMessage = msg
        } else {
          // console.error(`Payment error for ${currentCartRef.current?.code}`, currentCartRef, res)
          // errMessage = `Payment error for ${currentCartRef.current?.code}`
          console.error(`Payment error`, res?.detail || '')
          errMessage = `Payment error`
        }
        throw {
          status: errStatus,
          message: errMessage
        }
      }
    }
  }

  const updateLocalData = (
    result: CreatedCart & UpdatedCart & PayPalTwoStepCreateRequest,
    isBuyNow: boolean,
    currentItems: SfTypes.Item[]
  ) => {
    setLocalIsBuyNow(isBuyNow ?? false)
    if (result.id) setLocalOrderId(result.id)
    if (result.items) {
      const updatedItems: SfTypes.PriceChangedVariantItems = {}
      currentItems = currentItems.map((item) => {
        const price = result.items?.[item.id]
        if (price !== item.price && item.variant_id)
          updatedItems[item.variant_id] = { default_price: price }
        return {
          ...item,
          price: price ?? item.price
        }
      })
      setGlobalState({ [priceChangedVariantItemsKey(slugRef.current)]: updatedItems })
    }
    setLocalBuyNowOrder((prev: any) => ({
      ...prev,
      ...(result.id ? { id: result.id /*, code: result.code*/ } : {}),
      ...(isBuyNow ? { ...result, items: currentItems } : {})
    }))
    setLocalOrder((prev: any) => ({
      ...prev,
      ...(result.id ? { id: result.id /*, code: result.code*/ } : {}),
      ...(!isBuyNow ? { ...result, items: currentItems } : {})
    }))
  }

  const reviewPaypalTwoStep = async (data: any, actions: any, isBuyNow = false) => {
    try {
      const isCheckoutNow = isBuyNow ?? localIsBuyNowRef.current ?? false
      const result = await PayPaypalexTwoStepService.review2({
        i: localSessionId || undefined,
        l: encodeURIComponent(window.location.href) || '',
        f: isCheckoutNow ? 'checkout' : 'cart',
        id: localOrderIdRef.current,
        body: {
          order_id: data?.orderID
        }
      })
      updateLocalData(
        result,
        localIsBuyNowRef.current ?? false,
        (localIsBuyNowRef.current
          ? localBuyNowOrderRef?.current?.items
          : localOrderRef?.current?.items) as SfTypes.Item[]
      )
      setLocalShippingAddress(result?.shipping)
      setLocalPalpalOrderId(data?.orderID)
      return result
    } catch (err: any) {
      const res = err?.response
      const errStatus = res?.status
      let errMessage = ''
      if (errStatus === 404) {
        console.warn(
          // `Cart not found or already paid for ${currentCartRef.current?.code}`,
          `Cart not found or already paid`,
          currentCartRef.current,
          res
        )
        // errMessage = `Cart not found or already paid for ${currentCartRef.current?.code}`
        errMessage = `Cart not found or already paid`
        resetCart()
      } else if (errStatus === 402 || errStatus === 550 || errStatus === 555) {
        // Client error OR Payment Gateway Processing & Finalizing error
        const msg = res?.data?.detail
        const instance = res?.data?.instance
        if (res.status === 402 && instance === 'INSTRUMENT_DECLINED') {
          return actions.restart() // Recoverable state, per: https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
        }
        console.warn(
          errStatus === 402
            ? // ? `Client input error for ${currentCartRef.current?.code}`
              // : `Payment Gateway Processing & Finalizing error for ${currentCartRef.current?.code}`,
              `Client input error`
            : `Payment Gateway Processing & Finalizing error`,
          currentCartRef.current,
          msg,
          res
        )
        errMessage = msg
      } else {
        // console.error(`Payment error for ${currentCartRef.current?.code}`, currentCartRef, res)
        // errMessage = `Payment error for ${currentCartRef.current?.code}`
        console.error(`Payment error`, errStatus, res?.data?.detail || '')
        errMessage = `Payment error`
      }
      throw {
        status: errStatus,
        message: errMessage
      }
    }
  }

  const capturePaypalTwoStep = async (data: any, isBuyNow = false) => {
    if (localIsPaying) return
    try {
      setIsPayingPaypal(true)
      const isCheckoutNow = isBuyNow ?? localIsBuyNowRef.current ?? false
      const result = await PayPaypalexTwoStepService.approve2({
        i: localSessionId || undefined,
        l: encodeURIComponent(window.location.href) || '',
        f: isCheckoutNow ? 'checkout' : 'cart',
        id: localOrderIdRef.current,
        body: {
          order_id: data
        }
      })
      let order: SfTypes.Order
      if (result?.id) {
        order = handlePurchasedOrder(result)
      } else {
        order = approveCart('PAYPAL', {
          ...currentCartRef.current,
          shipping: result?.shipping,
          code: result?.code
        })
      }
      return {
        order
      }
    } catch (err: any) {
      const isCheckoutNow = isBuyNow ?? localIsBuyNowRef.current ?? false
      const result = await PayPaypalexTwoStepService.approve2({
        i: localSessionId || undefined,
        l: encodeURIComponent(window.location.href) || '',
        f: isCheckoutNow ? 'checkout' : 'cart',
        id: localOrderIdRef.current,
        body: {
          order_id: data
        }
      })
      if (result) {
        let order: SfTypes.Order
        if (result?.id) {
          order = handlePurchasedOrder(result)
        } else {
          order = approveCart('PAYPAL', {
            ...currentCartRef.current,
            shipping: result?.shipping,
            code: result?.code
          })
        }
        return {
          order
        }
      }

      const res = err?.response
      const errStatus = res?.status
      let errMessage = ''
      if (errStatus === 404) {
        console.warn(
          // `Cart not found or already paid for ${currentCartRef.current?.code}`,
          `Cart not found or already paid`,
          currentCartRef.current,
          res
        )
        // errMessage = `Cart not found or already paid for ${currentCartRef.current?.code}`
        errMessage = `Cart not found or already paid`
        resetCart()
      } else if (errStatus === 402 || errStatus === 550 || errStatus === 555) {
        // Client error OR Payment Gateway Processing & Finalizing error
        const msg = res?.data?.detail
        console.warn(
          errStatus === 402
            ? // ? `Client input error for ${currentCartRef.current?.code}`
              // : `Payment Gateway Processing & Finalizing error for ${currentCartRef.current?.code}`,
              `Client input error`
            : `Payment Gateway Processing & Finalizing error`,
          currentCartRef.current,
          msg,
          res
        )
        errMessage = msg
      } else {
        // console.error(`Payment error for ${currentCartRef.current?.code}`, currentCartRef, res)
        // errMessage = `Payment error for ${currentCartRef.current?.code}`
        console.error(`Payment error with Paypal`, `${res}`)
        errMessage = `Payment error`
      }
      throw {
        status: errStatus,
        message: errMessage
      }
    } finally {
      setIsPayingPaypal(false)
    }
  }

  const cancelPaypalTwoStep = async (data: any, isBuyNow = false) => {
    try {
      const isCheckoutNow = isBuyNow ?? localIsBuyNowRef.current ?? false
      setIsPayingPaypal(false)
      await PayPaypalexTwoStepService.cancel2({
        i: localSessionId || undefined,
        l: encodeURIComponent(window.location.href) || '',
        id: localOrderIdRef.current ?? '',
        f: isCheckoutNow ? 'checkout' : 'cart',
        body: {
          order_id: data?.orderID
        }
      })
    } catch (err: any) {
      console.error(err?.response?.data)
    }
  }

  const getTokenBrainTree = async (paygateId: string) => {
    try {
      const token = await PayBraintreeService.br({ paygateId })
      return token
    } catch (error: any) {
      console.error('Get Token Braintree Error ', error)
      throw error
    }
  }

  const payByBraintree = async (braintreeInstance: HostedFields, threedInstance: ThreeDSecure) => {
    try {
      const payload = await braintreeInstance?.tokenize()
      let cancel = false
      threedInstance?.on('lookup-complete', (data, next) => {
        if (data) {
          setPaymentTypeOrder(data?.paymentMethod?.details?.cardType?.toLowerCase())
        }
        next?.()
      })
      threedInstance?.on('customer-canceled', () => {
        console.log('customer-canceled')
        cancel = true
      })

      const verifyResponse = await threedInstance?.verifyCard({
        amount: currentCartRef.current?.amount || 0,
        nonce: payload?.nonce || '',
        bin: payload?.details.bin || ''
      })
      if (cancel) throw 'cancel'
      if (
        verifyResponse?.liabilityShifted ||
        (!verifyResponse?.liabilityShifted && !verifyResponse?.liabilityShiftPossible) ||
        (verifyResponse?.liabilityShifted && verifyResponse?.liabilityShiftPossible)
      ) {
        const result = await PayBraintreeService.br1({
          cartId: localOrderId || '',
          i: localSessionId || undefined,
          l: encodeURIComponent(window.location.href) || '',
          body: {
            nonce: verifyResponse?.nonce
          }
        })
        const order = approveCart('BRAINTREE', {
          ...currentCartRef.current,
          cardInfo: { card_first4: result?.card_first4, card_last4: result?.card_last4 },
          code: result?.code
        })
        return {
          order
        }
      } else {
        console.error(
          'Payment failed 3D secure Braintree with card type',
          payload?.details?.cardType
        )
        throw {
          status: '',
          message: 'Payment failed. Please try again with an alternate card/payment method.'
        }
      }
    } catch (err: any) {
      if (err?.code === 'THREEDS_LOOKUP_VALIDATION_ERROR') {
        throw err?.details?.originalError?.details?.originalError?.error
      }
      const errCode = err?.response?.status
      if (errCode === 404) {
        resetCart()
      }
      console.error('Braintree Checkout error', err?.code, err)
      throw err
    } finally {
      setLocalIsPaying(false)
    }
  }

  return {
    payByCard,
    initPaypal,
    capturePaypal,
    cancelPaypal,
    errorPaypal,
    payByStripe,
    initPaypalTwoStep,
    reviewPaypalTwoStep,
    capturePaypalTwoStep,
    cancelPaypalTwoStep,
    getTokenBrainTree,
    payByBraintree,
    payByCardBraintree
  }
}
