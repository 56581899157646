import * as immutable from 'object-path-immutable'

import { buildCollection, compileReviews, deepCloneObject, updatePage } from '@libs/server'
import {
  getLocalState,
  setPrefix,
  syncLocalTab
} from '@libs/client/hooks/useGlobalState/StateLocal'
import { paygate_adapter, store_setting_template, store_type } from '@libs/common/models/constant'
import { ParsedUrlQuery } from 'querystring'
import { customConfig, hardcodePaygateContact } from './hardcode'
import { initRDT } from '@libs/client/hooks/useGlobalState/ReduxDevTool'
import { setGlobalState } from '@libs/client'
import getConfig from 'next/config'
import https from 'https'
import axios from 'axios'

const agent = new https.Agent({
  rejectUnauthorized: false // This bypasses SSL certificate validation
})

const acceptedVersionContents = getConfig().publicRuntimeConfig.abContentAcc

const defaultStore: S3Types.store = {
  setting: {
    template: store_setting_template.default,
    settings: {
      general: {
        id: '',
        description: '',
        domain: '',
        favicon: '',
        logo: {
          image: ''
        },
        meta: {
          description: '',
          dmca_site_verification: '',
          facebook_domain_verification: '',
          google_site_verification: '',
          image: '',
          title: ''
        },
        name: '',
        publisher_id: '',
        store_fee: {},
        after_sale: {},
        title: '',
        type: store_type.SELLPAGE
      }
    }
  },
  pages: {}
}

export interface ServerState {
  domains?: S3Types.domains
  publisher?: S3Types.publisher
  store?: S3Types.store
  promotions?: {
    default: S3Types.promotions
    [slug: string]: S3Types.promotions
  }
  paygates?: { creditcard?: S3Types.paygate; paypalex?: S3Types.paygate }
  static?: {
    [static_slug: string]: S3Types.default_store_static_page
  }
  isPreview?: boolean
  analytics?: {
    pixel_ids: string[]
    tiktok_ids: string[]
    snapchat_ids: string[]
    pinterest_ids: string[]
    google_conversion_id: string
    google_conversion_label: string
  }
  customConfig?: any
  settingDefault?: S3Types.default_store
  surveys?: S3Types.surveys
}

export interface InactiveResponse {
  page_data: S3Types.page
  page_version: S3Types.page
}

export function initializeClientState() {
  const INIT_STATE = {
    ...setPrefix(window?.__NEXT_DATA__?.props?.pageProps, ''),
    ...getLocalState()
  }
  setGlobalState(INIT_STATE, true)
  initRDT(INIT_STATE)
  // Set to false to avoid clearing state, this should be changed once we apply sync between tabs of browser.
  syncLocalTab((data: any) => setGlobalState(data))
}

export async function initializeServerState(
  domain: string,
  subpath: string,
  pathname: string,
  query?: ParsedUrlQuery,
  versionCookie?: string,
  pVersionCookie?: string,
  cookies?: { [key: string]: string }
) {
  const dataFromBuild = await buildServerState({
    domain,
    subpath,
    pathname,
    query,
    versionCookie,
    pVersionCookie,
    cookies
  })
  const INIT_STATE = setPrefix(dataFromBuild, '')
  setGlobalState(INIT_STATE, true)

  return INIT_STATE
}

async function buildServerState(params: {
  domain: string
  subpath: string
  pathname: string
  query?: ParsedUrlQuery
  versionCookie?: string
  pVersionCookie?: string
  cookies?: { [key: string]: string }
}) {
  // const t = process.hrtime()
  const { domain, subpath, versionCookie, pVersionCookie, cookies } = params
  const serverState: ServerState = {}
  if (process._repository.default_store?.setting) {
    const defaultSetting = deepCloneObject(
      process._repository?.default_store
    ) as S3Types.default_store
    removeUnexpectedDomain(defaultSetting, domain)
    serverState.settingDefault = defaultSetting
    delete serverState?.settingDefault?.setting?.static
  }
  if (process._repository.domains?.[domain]) {
    serverState.domains = { [domain]: process._repository?.domains[domain] }
  }

  const store_id = serverState?.domains?.[domain]?.store_id

  if (store_id) {
    let store = process._repository?.stores?.[store_id] as S3Types.store
    const pages = process._repository?.pages || {}
    if (store) store = deepCloneObject(store)

    const publisher =
      process._repository.publishers?.[store?.setting?.settings?.general?.publisher_id ?? '1']

    const isContentVersion = !acceptedVersionContents?.includes(publisher?.email)

    if (store && store.pages) {
      if (store.setting?.settings?.general?.type === store_type.SELLPAGE) {
        if (store.pages?.setting) {
          const id = store.pages?.setting?.settings?.general?.id
          store.pages = pages?.[id] as S3Types.pages

          const pageVersionId =
            pVersionCookie || store.pages?.setting?.settings.general.priority_version_id
          let pageVersion = process._repository.page_versions?.[pageVersionId || '']

          const isPageInactiveInMq =
            !store?.pages?.setting && process._repository._mq_inactive?.pages?.pages?.[id]

          const isPageVersionInactiveInMq =
            !pageVersion &&
            process._repository._mq_inactive?.page_versions?.page_versions?.[pageVersionId || '']

          try {
            if (pageVersionId) {
              if (isPageInactiveInMq && isPageVersionInactiveInMq) {
                const { page_data, page_version } = await getS3InactiveApi(
                  domain,
                  id,
                  pageVersionId
                )
                updatePage(page_version)
                store.pages = page_data as S3Types.pages
                pageVersion = page_version
              } else if (store?.pages?.setting && isPageVersionInactiveInMq) {
                const { page_version } = await getS3InactiveApi(domain, '0', pageVersionId)
                updatePage(page_version)
                pageVersion = page_version
              }
            } else {
              if (isPageInactiveInMq) {
                const { page_data, page_version } = await getS3InactiveApi(domain, id, '0')
                store.pages = page_data as S3Types.pages
                updatePage(page_version)
                pageVersion = page_version
              }
            }
          } catch (error) {
            console.error(error)
          }

          if (store.pages) {
            if (isContentVersion) {
              store.pages = {
                ...store.pages, // old
                ...pageVersion,
                setting: {
                  ...(store?.pages?.setting || {}),
                  ...(pageVersion?.setting || {}),
                  settings: {
                    ...(pageVersion?.setting?.settings || {}),
                    ...(store?.pages?.setting?.settings || {}),
                    general: {
                      ...(store?.pages?.setting?.settings?.general || {}),
                      ...(pageVersion?.setting?.settings?.general || {})
                    }
                  }
                }
              } as any
            }
            const { reviews, ratings } = compileReviews(
              store?.pages?.reviews || [],
              store?.pages?.ratings as S3Types.page_rating
            )
            store.pages = immutable.set(store?.pages || {}, `reviews`, reviews)
            store.pages = immutable.set(store?.pages || {}, `ratings`, ratings)

            if (store?.pages?.setting?.settings && versionCookie == 'v14') {
              store.pages.setting.settings.social_proof.countdown_time = 21600
            }
            if (store?.pages?.setting?.settings && versionCookie == 'vpo1') {
              const isPreOrder =
                process._repository.default_store?.setting?.settings?.general?.pre_order?.[
                  domain || ''
                ]
              store.pages.setting.settings.general.pre_order = !!isPreOrder
            }
            store.setting.settings.version = versionCookie
          }
        }
      } else {
        if (subpath && store?.pages?.[subpath]) {
          const pageId = store?.pages?.[subpath]?.setting?.settings?.general?.id || ''
          const page = pages?.[pageId] as S3Types.pages

          const pageVersionId =
            pVersionCookie || page?.setting?.settings?.general?.priority_version_id || ''
          const pageVersion = process._repository.page_versions?.[pageVersionId]

          const isPageInactiveInMq =
            !page?.setting && process._repository._mq_inactive?.pages?.pages?.[pageId] //inactive but not in mq

          const isPageVersionInactiveInMq =
            !pageVersion?.setting &&
            process._repository._mq_inactive?.page_versions?.page_versions?.[pageVersionId] //inactive but not in mq

          try {
            if (pageVersionId) {
              if (isPageInactiveInMq && isPageVersionInactiveInMq) {
                const { page_data, page_version } = await getS3InactiveApi(
                  domain,
                  pageId,
                  pageVersionId
                )
                updatePage(page_version)
                pages[pageId] = page_data as S3Types.pages
                if (process._repository?.page_versions) {
                  process._repository.page_versions[pageVersionId] = page_version
                }
              } else if (page?.setting && isPageVersionInactiveInMq) {
                const { page_version } = await getS3InactiveApi(domain, '0', pageVersionId)
                updatePage(page_version)
                if (process._repository?.page_versions) {
                  process._repository.page_versions[pageVersionId] = page_version
                }
              }
            } else {
              if (isPageInactiveInMq) {
                const { page_data, page_version } = await getS3InactiveApi(domain, pageId, '0')
                updatePage(page_version)
                pages[pageId] = page_data as S3Types.pages
                const priority_version_id =
                  page_data?.setting?.settings?.general?.priority_version_id || ''
                if (process._repository?.page_versions && priority_version_id) {
                  process._repository.page_versions[priority_version_id] = page_version
                }
              }
            }
          } catch (error) {
            console.error(error)
          }
        }
        Object.entries(store.pages).forEach(([page_path, page]) => {
          if (page) {
            const pageId = page?.setting?.settings?.general?.id || ''
            page = pages?.[pageId] as S3Types.pages

            if (store?.pages && store?.pages[page_path]) {
              if (isContentVersion) {
                const key = `pversion${page_path ? `_${page_path}` : ''}`
                const pageVersion =
                  process._repository.page_versions?.[
                    `${
                      cookies?.[key] ||
                      page?.setting?.settings?.general?.priority_version_id ||
                      Object.keys(page?.setting?.settings?.traffic_config || {})?.[0] ||
                      ''
                    }`
                  ]
                store.pages[page_path] = {
                  ...page,
                  ...pageVersion,
                  setting: {
                    ...(page?.setting || {}),
                    ...(pageVersion?.setting || {}),
                    settings: {
                      ...(pageVersion?.setting?.settings || {}),
                      ...(page?.setting?.settings || {}),
                      general: {
                        ...(page?.setting?.settings?.general || {}),
                        ...(pageVersion?.setting?.settings?.general || {})
                      }
                    }
                  }
                } as any
              } else {
                store.pages[page_path] = page
              }
              const { reviews, ratings } = compileReviews(
                store?.pages?.[page_path]?.reviews || [],
                store?.pages?.[page_path]?.ratings as S3Types.page_rating
              )
              store.pages = immutable.set(store?.pages || {}, `${page_path}.reviews`, reviews)
              store.pages = immutable.set(store?.pages || {}, `${page_path}.ratings`, ratings)
            }
            if (page?.setting?.settings && versionCookie == 'v14') {
              page.setting.settings.social_proof.countdown_time = 21600
            }

            if (store?.pages?.[page_path]?.setting?.settings?.general) {
              ;(store.pages as any)[page_path || ''].setting.settings.general.pre_order = false
              if (versionCookie == 'vpo1') {
                const isSlugPreOrder =
                  process._repository.default_store?.setting?.settings?.general?.pre_order?.[
                    domain || ''
                  ]

                const isPreOrder = isSlugPreOrder?.includes(page_path)
                ;(store.pages as any)[page_path].setting.settings.general.pre_order = !!isPreOrder
              }
            }
          }
        })
        if (store.setting) store.setting.settings.version = versionCookie
        if (store.setting && process._repository?.pbases) {
          const pageId = store?.pages?.[subpath]?.setting?.settings?.general?.page_id || ''
          const page = pages?.[pageId] as S3Types.pages
          store.setting.settings.general.logrocket = validateStatusLogRocket(
            process._repository?.pbases?.[page?.setting?.settings?.general?.pbase_id || ''],
            domain,
            subpath
          )
        }

        store.collection = buildCollection(store, versionCookie || '')
        Object.keys(store.pages).map((page_path) => {
          if (page_path === subpath) {
            const page = store?.pages?.[subpath]
            if (page && store?.pages) {
              store.pages[subpath] = page
            }
          } else if (store?.pages) {
            store.pages = immutable.set(store?.pages || {}, `${page_path}.setting`, {})
            store.pages = immutable.set(store?.pages || {}, `${page_path}.reviews`, undefined)
          }
        })
      }
      serverState.analytics = getAnalytics(store.pages, subpath)
      serverState.promotions = getPromotions(store)
    }

    serverState.store = store

    if (publisher) {
      serverState.publisher = publisher
      const storeAddress = store?.setting?.settings?.configurations?.store_address || ''
      serverState.paygates = paymentGatewaysHardcoded(
        domain,
        getPaymentGateways(publisher),
        storeAddress,
        versionCookie
      )
    }

    serverState.customConfig = customConfig(domain, subpath)
  }

  serverState.isPreview = false
  serverState.surveys = process._repository?.surveys

  return serverState
}

const getPaymentGateways = (publisher: S3Types.publisher) => {
  const payments = publisher.payments
  const creditcardType = payments?.creditcard
  const paypalexType = payments?.paypalex
  const creditcardId =
    creditcardType === 'DEFAULT'
      ? process._repository?.default_paygate?.creditcard
      : creditcardType === 'SPECIFIC'
      ? payments?.creditcard_id
      : null
  const paypalexId =
    paypalexType === 'DEFAULT'
      ? process._repository?.default_paygate?.paypalex
      : paypalexType === 'SPECIFIC'
      ? payments?.paypalex_id
      : null
  return {
    ...(creditcardId && { creditcard: process._repository.paygates?.[creditcardId] }),
    ...(paypalexId && { paypalex: process._repository.paygates?.[paypalexId] })
  }
}

const getAnalytics = (pages?: S3Types.pages, subpath = '') => {
  if (pages?.setting) {
    return {
      pixel_ids: pages?.setting.settings?.analytics?.pixel_ids ?? [],
      tiktok_ids: pages?.setting.settings?.analytics?.tiktok_ids ?? [],
      snapchat_ids: pages?.setting.settings?.analytics?.snapchat_ids ?? [],
      pinterest_ids: pages?.setting.settings?.analytics?.pinterest_ids ?? [],
      google_conversion_id: pages?.setting.settings?.analytics?.google_conversion_id ?? '',
      google_conversion_label: pages?.setting.settings?.analytics?.google_conversion_label ?? ''
    }
  } else {
    if (pages) {
      if (pages?.[subpath]) {
        return {
          pixel_ids: pages?.[subpath]?.setting?.settings?.analytics?.pixel_ids ?? [],
          tiktok_ids: pages?.[subpath]?.setting?.settings?.analytics?.tiktok_ids ?? [],
          snapchat_ids: pages?.[subpath]?.setting?.settings?.analytics?.snapchat_ids ?? [],
          pinterest_ids: pages?.[subpath]?.setting?.settings?.analytics?.pinterest_ids ?? [],
          google_conversion_id:
            pages?.[subpath]?.setting?.settings?.analytics?.google_conversion_id ?? '',
          google_conversion_label:
            pages?.[subpath]?.setting?.settings?.analytics?.google_conversion_label ?? ''
        }
      }
    }
    return {
      pixel_ids: [],
      tiktok_ids: [],
      snapchat_ids: [],
      pinterest_ids: [],
      google_conversion_id: '',
      google_conversion_label: ''
    }
  }
}

const getPromotions = (store: S3Types.store): ServerState['promotions'] => {
  const promotionMapping = (promotions: S3Types.promotions): S3Types.promotions => {
    return Object.fromEntries(
      Object.entries(promotions).map(([key, value]) => [
        key,
        {
          code: value.code,
          value: value.value,
          type: value.type
        }
      ])
    )
  }

  return {
    default: promotionMapping(process._repository.default_store?.promotions ?? {}),
    ...Object.fromEntries(
      Object.entries(store.pages ?? {}).map(([slug, page]) => [
        slug,
        promotionMapping(page?.promotions ?? {})
      ])
    )
  }
}

const validateStatusLogRocket = (pbases: S3Types.pbase, domain: string, slug = '') => {
  const expiration =
    pbases?.logrocket_config?.[`${domain}${slug?.length ? `/${slug}` : ''}`]?.expiration || 0
  return expiration > new Date()?.getTime()
}

const paymentGatewaysHardcoded = (
  domain: string,
  paygates: ServerState['paygates'],
  storeAddress = '',
  version = ''
) => {
  const paygateContact = hardcodePaygateContact(domain)
  return {
    ...(paygates?.creditcard && {
      creditcard: {
        ...paygates?.creditcard,
        contact: {
          ...paygates?.creditcard.contact,
          ...paygateContact,
          ...(storeAddress ? { address: storeAddress } : {})
        },
        ...(version == 'br1'
          ? {
              id: process.env.BR_ID || '6np02be0mn48qm8mdsb3rg2xx', // paygateId
              adapter: paygate_adapter.BRAINTREE
            }
          : {})
      }
    }),
    ...(paygates?.paypalex && {
      paypalex: {
        ...paygates?.paypalex,
        contact: {
          ...paygates?.paypalex.contact,
          ...paygateContact,
          ...(storeAddress ? { address: storeAddress } : {})
        }
      }
    })
  }
}

export async function initializePreviewServerState(pathname: string, query?: ParsedUrlQuery) {
  const INIT_STATE = await buildPreviewServerState({ pathname, query })
  setGlobalState(INIT_STATE, true)

  return INIT_STATE
}

async function buildPreviewServerState(params: { pathname: string; query?: ParsedUrlQuery }) {
  const { pathname, query } = params
  const serverState: ServerState = {}

  serverState.store = defaultStore

  const _static: {
    [static_slug: string]: S3Types.default_store_static_page
  } = {}
  if (pathname.startsWith('/static') && query?.static_slug) {
    if (process._repository?.default_store?.setting?.static?.[query.static_slug as string]) {
      _static[query.static_slug as string] =
        process._repository?.default_store?.setting?.static?.[query.static_slug as string]
    }

    serverState.static = _static
  }

  serverState.isPreview = true
  return serverState
}

const removeUnexpectedDomain = (setting: S3Types.default_store, domain = '') => {
  if (domain) {
    setting.setting.settings.general.onselless_sellable = setting?.setting?.settings.general.onselless_sellable
      ?.split(',')
      ?.filter((x) => x.includes(domain))
      ?.join(',')

    setting.setting.settings.general.shophelp_disabled = setting?.setting?.settings.general.shophelp_disabled
      ?.split(',')
      ?.filter((x) => x.includes(domain))
      ?.join(',')
    setting.setting.settings.general.after_sale = Object.keys(
      setting?.setting?.settings?.general?.after_sale || {}
    )?.some((x) => x.includes(domain))
      ? { [domain]: setting.setting.settings.general.after_sale?.[domain] }
      : { default: setting.setting.settings.general.after_sale?.default }
    delete setting.setting.settings.general.traffic
  }
  return setting
}

const getS3InactiveApi = async (baseHost = '', pageId: string, pageVersionId: string) => {
  // const isLocal = process.env.env == 'local'
  // const pageId = `${pageId || ''}`
  // const pageVersionId = `${pVersionId || ''}`
  // const route = `https://${baseHost}${
  //   isLocal ? ':3000' : ''
  // }/api/page-inactive/${idPage}/${idVersion}`
  const route = `${
    process.env.SERVICE_NAME || 'http://cms-template-default-internal:80'
  }/iapi/page-inactive/${pageId || ''}/${pageVersionId || ''}`
  console.log(`Load inactive in domain ${baseHost} with route ${route}`)
  const response = await axios.get(route, {
    httpsAgent: agent
  })
  return response?.data as InactiveResponse
}
