import getConfig from 'next/config'

const cdnBasePath = getConfig().publicRuntimeConfig.cdnBasePath
const cdnImages = getConfig().publicRuntimeConfig.cdnImages
const previewImagePath = getConfig().publicRuntimeConfig.previewImagePath

export const iconPath = (url: string) => `${cdnBasePath}/${cdnImages}/${url}`
export const imagePath = (url: string) => `${cdnBasePath}/${url}`

export const previewImage = (path = '') => `${previewImagePath}${path}`
