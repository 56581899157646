import { getImportedScript } from './importScript'
// Tracking analytic events here
/** ----- Facebook pixel track ----- */
export const fbpTrack = async (
  eventName: Types.PixelStandardEvent,
  data: Types.PixelEventData,
  eventID?: string
) => {
  const pixel = await getImportedScript(isFbpInit)
  if (pixel) {
    // console.log('Fbp is ready', eventName, Date.now())
    pixel(
      'track',
      eventName,
      { ...{ content_type: 'product', currency: 'USD' }, ...data },
      { eventID }
    )
  }
}

export const fbpTrackSingle = async (
  eventName: Types.PixelStandardEvent,
  data: Types.PixelEventData,
  ids: string[],
  eventID?: string
) => {
  if (ids && ids.length > 0) {
    const pixel = await getImportedScript(isFbpInit)
    if (pixel) {
      console.log('Fbp is ready', eventName, ids, Date.now())
      ids.forEach((id) => {
        // pixel('addPixelId', id)
        id &&
          pixel(
            'trackSingle',
            id,
            eventName,
            { ...{ content_type: 'product', currency: 'USD' }, ...data },
            { eventID }
          )
      })
    }
  }
}

export const fbpTrackInitAdvanceMatching = async (
  data: Types.PixelEventMatchingData,
  ids: string[]
) => {
  if (ids && ids.length > 0) {
    const pixel = await getImportedScript(isFbpInit)
    if (pixel) {
      // console.log('Fbp is ready init advance matching', ids, Date.now())
      ids.forEach((id) => {
        // pixel('addPixelId', id)
        id && pixel('init', id, { ...data })
      })
    }
  }
}

const isFbpInit = () => {
  //@ts-ignore
  return window.fbq
}
/** ----- END Facebook pixel track ----- */

// Tracking analytic events here
/** ----- Snapchat pixel track ----- */
export const snapchatTrack = async (
  eventName: Types.SnapchatStandardEvent,
  data: Types.SnapchatEventData
) => {
  const snapchatPixel = await getImportedScript(isSnapchatInit)
  if (snapchatPixel) {
    // console.log('Snapchat is ready', eventName, Date.now())
    snapchatPixel('track', eventName, { ...{ item_category: 'product', currency: 'USD' }, ...data })
  }
}

export const snapchatTrackSingle = async (
  eventName: Types.SnapchatStandardEvent,
  data: Types.SnapchatEventData,
  ids: string[]
) => {
  if (ids && ids.length > 0) {
    const snapchatPixel = await getImportedScript(isSnapchatInit)
    if (snapchatPixel) {
      // console.log('Snapchat is ready', eventName, ids, Date.now())
      ids.forEach((id) => {
        id &&
          snapchatPixel('track', eventName, {
            ...{ item_category: 'product', currency: 'USD' },
            ...data
          })
      })
    }
  }
}

const isSnapchatInit = () => {
  //@ts-ignore
  return window.snaptr
}
/** ----- END Snapchat pixel track ----- */

// Tracking analytic events here
/** ----- pinterest pixel track ----- */
export const pinterestTrack = async (
  eventName: Types.PinterestStandardEvent,
  data: Types.PinterestCustomEventData
) => {
  const pinterestPixel = await getImportedScript(isPinterestInit)
  if (pinterestPixel) {
    // console.log('Pinterest is ready', eventName, Date.now())
    pinterestPixel('track', eventName, {
      ...{ currency: 'USD' },
      ...data
    })
  }
}
const setLoggedEvent = new Set<string>()

export const pinterestPixelTrackSingle = async (
  eventName: Types.PinterestStandardEvent,
  data: Types.PinterestCustomEventData,
  ids: string[]
) => {
  if (ids && ids.length > 0) {
    const pinterestPixel = await getImportedScript(isPinterestInit)
    if (pinterestPixel) {
      // console.log('Pinterest is ready', eventName, ids, Date.now())
      ids.forEach((id) => {
        if (!setLoggedEvent.has(id)) {
          setLoggedEvent.add(id)
          pinterestPixel('load', id)
          pinterestPixel('page')
        }
        id &&
          pinterestPixel('track', eventName, {
            ...{ currency: 'USD' },
            ...data
          })
      })
    }
  }
}

const isPinterestInit = () => {
  //@ts-ignore
  return window.pintrk
}
/** ----- END Pinterest pixel track ----- */

/** ----- Tiktok pixel track ----- */
export const ttqTrack = async (
  eventName: Types.TiktokPixelStandardEvent,
  data: Types.TiktokPixelEventData
) => {
  const tiktokPixel = await getImportedScript(isTiktokInit)
  if (tiktokPixel) {
    // console.log('Tiktok pixel is ready', eventName, Date.now())
    tiktokPixel.track(eventName, { ...{ content_type: 'product', currency: 'USD' }, ...data })
  }
}

export const ttqTrackSingle = async (
  eventName: Types.TiktokPixelStandardEvent,
  data: Types.TiktokPixelEventData,
  ids: string[]
) => {
  if (ids && ids.length > 0) {
    const tiktokPixel = await getImportedScript(isTiktokInit)
    if (tiktokPixel) {
      // console.log('Tiktok pixel is ready', eventName, ids, Date.now())
      ids.forEach((id) => {
        // tiktokPixel.load(id)
        id &&
          tiktokPixel
            .instance(id)
            .track(eventName, { ...{ content_type: 'product', currency: 'USD' }, ...data })
      })
    }
  }
}

export const ttqTrackSingleIdentify = async (data: Types.TiktokPixelIdentify) => {
  const tiktokPixel = await getImportedScript(isTiktokInit)
  if (tiktokPixel) {
    // console.log('Tiktok pixel Identify is ready', Date.now())
    tiktokPixel.identify(data)
  }
}

const isTiktokInit = () => {
  //@ts-ignore
  return window.ttq
}
/** ----- END Tiktok pixel track ----- */

/** ----- GA track ----- */
export const gaSet = async (eventName: string, data: string) => {
  const ga = await getImportedScript(isGaInit)
  if (ga) {
    console.log('Ga is ready', eventName, Date.now())
    ga('set', eventName, data)
  }
}

export const gaSend = async (eventName: string) => {
  const ga = await getImportedScript(isGaInit)
  if (ga) {
    // console.log('Ga is ready', eventName, Date.now())
    ga('send', eventName)
  }
}

const isGaInit = () => {
  //@ts-ignore
  return window.ga
}
/** ----- END GA track ----- */

/** ----- Gtag track ----- */
export const gtagPageTracking = async (gaIds: string[], pagePath: string) => {
  const gtag = await getImportedScript(isGtagInit)
  gaIds = [...gaIds]
  if (gtag && gaIds.length > 0) {
    console.log('Gtag is ready', gaIds, pagePath, Date.now())
    gaIds.forEach((gaId) => {
      gtag('config', gaId, { page_path: pagePath })
    })
  }
}

export const gtagTrack = async (
  eventName: Types.GtagStandardEvent,
  data: Omit<Types.GtagEventData, 'currency'>
) => {
  const gtag = await getImportedScript(isGtagInit)
  if (gtag) {
    console.log('Gtag is ready', eventName, Date.now())
    gtag('event', eventName, { currency: 'USD', ...data })
  }
}

export const gtagTrackConversion = async (
  eventName: Types.GtagStandardEvent,
  id?: string,
  value?: number,
  orderId?: string
) => {
  const gtag = await getImportedScript(isGtagInit)
  if (gtag) {
    console.log('Gtag is ready', eventName, Date.now())
    gtag('event', eventName, { send_to: id, currency: 'USD', value, transaction_id: orderId })
  }
}

export const gtagTrackEnhanceConversion = async (
  eventName: Types.GtagStandardEvent,
  address: Types.CustomerInfoGoogle
) => {
  const gtag = await getImportedScript(isGtagInit)
  if (gtag) {
    gtag('set', eventName, address)
  }
}

const isGtagInit = () => {
  //@ts-ignore
  return window.gtag
}
/** ----- ENDGtag track ----- */

/** ----- Start AXON in Applovin track ----- */

// export const axonTrackConversion = async (
//   eventName: Types.AxonStandardEvent,
//   id?: string,
//   value?: number,
//   orderId?: string
// ) => {
//   const axon = await getImportedScript(isAxonInit)
//   if (axon) {
//     console.log('Axon is ready', eventName, Date.now())
//     axon('event', eventName, { send_to: id, currency: 'USD', value, transaction_id: orderId })
//   }
// }

export const axonTrack = async (eventName: Types.AxonStandardEvent, data?: Types.AxonEventData) => {
  const axon = await getImportedScript(isAxonInit)
  if (axon) {
    console.log('Axon is ready', eventName, Date.now())
    if (data) {
      axon('track', eventName, { ...{ currency: 'USD' }, ...data })
    } else {
      axon('track', eventName)
    }
  }
}
const isAxonInit = () => {
  //@ts-ignore
  return window.axon
}
/** ----- END AXON in Applovin track ----- */
