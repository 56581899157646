import { DataFetchingAdapter } from '../adapters/types'
import { getDurationInSeconds, logger } from '../ultilities'

export type _mq_info_type = 'domains' | 'paygates' | 'pbases' | 'publishers' | 'surveys'

export type _mq_full_type = _mq_info_type | 'ppages' | 'stores'

export type _mq_active_type = 'pages' | 'stores' | 'domains' | 'page_versions'
export type _mq_inactive_type = 'pages' | 'page_versions'
const timeLoad = +(process.env.TIMELOAD || '') || 5000
export async function watchMqInfo(
  adapter: DataFetchingAdapter,
  type: _mq_info_type,
  callbackGet?: () => Promise<any>,
  ms = timeLoad,
  isLoad = false
) {
  try {
    const partMq: S3Types._mq = await adapter.getMq({ files: [type] })
    const mqInfo: S3Types._mq_info | undefined = partMq[type]

    if (
      mqInfo?.updated &&
      (!process._repository?._mq?.[type]?.updated ||
        mqInfo.updated !== process._repository?._mq?.[type]?.updated ||
        isLoad)
    ) {
      try {
        const t = process.hrtime()
        await callbackGet?.()
        logger.info(
          `Watch MQ[${type}] update completed in: ${getDurationInSeconds(t).toLocaleString()}s`
        )
      } catch (err2) {
        logger.error(`Watch MQ[${type}] update error`, err2)
      } finally {
        if (process._repository?._mq) process._repository._mq[type] = mqInfo
      }
    }
  } catch (err: any) {
    logger.error(`Watch MQ[${type}] failed`, err)
  }
  if (!isLoad) {
    setTimeout(() => watchMqInfo(adapter, type, callbackGet), ms)
  }
}

export async function watchMqPPages(
  adapter: DataFetchingAdapter,
  folder: 'ppages' | 'ppages_p10n',
  callbackPPages?: (ppage_ids?: string[]) => Promise<any>,
  ms = timeLoad,
  isLoad = false
) {
  try {
    const partMq: S3Types._mq = await adapter.getMq({ files: [folder] })

    const mqPPages: S3Types._mq_ppages | undefined = partMq[folder]
    if (
      mqPPages?.updated &&
      (!process._repository?._mq?.[folder]?.updated ||
        mqPPages.updated !== process._repository._mq[folder]?.updated ||
        isLoad)
    ) {
      if (mqPPages?.ppages) {
        const ppages_id = Object.entries(mqPPages.ppages).reduce(
          (acc: string[], [index, updated]) => {
            if (
              !process._repository?._mq?.[folder]?.ppages?.[index] ||
              updated !== process._repository?._mq?.[folder]?.ppages?.[index]
            ) {
              return [...acc, index]
            }
            return acc
          },
          []
        )

        try {
          logger.info(`Watch MQ[${folder}] Updating ppage_ids =`, ppages_id)
          const t = process.hrtime()
          await callbackPPages?.(ppages_id)
          logger.info(
            `Watch MQ[${folder}] update completed in: ${getDurationInSeconds(t).toLocaleString()}s`
          )
        } catch (err2) {
          logger.error(`Watch MQ[${folder}] default update error`, err2)
        }
      }
      if (process._repository?._mq) process._repository._mq[folder] = mqPPages
    }
  } catch (err: any) {
    logger.error(`Watch MQ[${folder}] failed`, err)
  }
  if (!isLoad) {
    setTimeout(() => watchMqPPages(adapter, folder, callbackPPages), ms)
  }
}

export async function watchMqPages(
  adapter: DataFetchingAdapter,
  folder: 'pages',
  callbackPages?: (page_ids?: string[]) => Promise<any>,
  ms = timeLoad,
  isLoad = false
) {
  try {
    const partMq: S3Types._mq_active = await adapter.getMqActive({ files: [folder] })

    const mqPages: S3Types._mq_pages | undefined = partMq[folder]
    if (
      mqPages?.updated &&
      (!process._repository?._mq_active?.[folder]?.updated ||
        mqPages.updated !== process._repository._mq_active[folder]?.updated ||
        isLoad)
    ) {
      if (mqPages?.pages) {
        const pages_id = Object.entries(mqPages.pages).reduce((acc: string[], [index, updated]) => {
          if (
            !process._repository?._mq_active?.[folder]?.pages?.[index] ||
            updated !== process._repository?._mq_active?.[folder]?.pages?.[index]
          ) {
            return [...acc, index]
          }
          return acc
        }, [])

        try {
          logger.info(`Watch MQ Active[${folder}] Updating page_ids =`, pages_id)
          const t = process.hrtime()
          await callbackPages?.(pages_id)
          logger.info(
            `Watch MQ[${folder}] update completed in: ${getDurationInSeconds(t).toLocaleString()}s`
          )
        } catch (err2) {
          logger.error(`Watch MQ Active[${folder}] default update error`, err2)
        }
      }
      if (process._repository?._mq_active) process._repository._mq_active[folder] = mqPages
    }
  } catch (err: any) {
    logger.error(`Watch MQ[${folder}] failed`, err)
  }
  if (!isLoad) {
    setTimeout(() => watchMqPages(adapter, folder, callbackPages), ms)
  }
}

export async function watchDefaultMqStores(
  adapter: DataFetchingAdapter,
  callbackDefault?: () => Promise<any>,
  callbackP10N?: () => Promise<any>,
  callbackStores?: (store_ids?: string[]) => Promise<any>,
  ms = timeLoad,
  isLoad = false
) {
  try {
    const partMq: S3Types._mq_active = await adapter.getMqActive({ files: ['stores'] })

    const mqStores: S3Types._mq_stores | undefined = partMq['stores']

    if (
      mqStores?.updated &&
      (!process._repository?._mq_active?.stores?.updated ||
        mqStores.updated !== process._repository._mq_active.stores.updated ||
        isLoad)
    ) {
      if (
        mqStores?.[`.default`] &&
        (!process._repository?._mq_active?.stores?.[`.default`] ||
          mqStores[`.default`] !== process._repository._mq_active.stores[`.default`])
      ) {
        try {
          const t = process.hrtime()
          await callbackDefault?.()
          logger.info(
            `Watch MQ[stores] default update completed in: ${getDurationInSeconds(
              t
            ).toLocaleString()}s`
          )
        } catch (err2) {
          logger.error(`Watch MQ[stores] default update error`, err2)
        }
      }
      if (
        mqStores?.[`.p10n`] &&
        (!process._repository?._mq?.stores?.[`.p10n`] ||
          mqStores[`.p10n`] !== process._repository._mq.stores[`.p10n`])
      ) {
        try {
          const t = process.hrtime()
          await callbackP10N?.()
          logger.info(
            `Watch MQ[stores] p10n update completed in: ${getDurationInSeconds(
              t
            ).toLocaleString()}s`
          )
        } catch (err2) {
          logger.error(`Watch MQ[stores] p10n update error`, err2)
        }
      }
      if (mqStores?.stores) {
        const stores_id = Object.entries(mqStores.stores).reduce(
          (acc: string[], [index, updated]) => {
            if (
              !process._repository?._mq_active?.stores?.stores?.[index] ||
              updated !== process._repository?._mq_active?.stores?.stores?.[index]
            ) {
              return [...acc, index]
            }
            return acc
          },
          []
        )

        try {
          logger.info(`Watch MQ[stores] Updating store_ids =`, stores_id)
          const t = process.hrtime()
          await callbackStores?.(stores_id)
          logger.info(
            `Watch MQ[stores] update completed in: ${getDurationInSeconds(t).toLocaleString()}s`
          )
        } catch (err2) {
          logger.error(`Watch MQ[stores] default update error`, err2)
        }
      }
      if (process._repository?._mq_active) process._repository._mq_active.stores = mqStores
    }
  } catch (err: any) {
    logger.error(`Watch MQ[stores] failed`, err)
  }
  if (!isLoad) {
    setTimeout(
      () => watchDefaultMqStores(adapter, callbackDefault, callbackP10N, callbackStores),
      ms
    )
  }
}

export async function watchMqDomain(
  adapter: DataFetchingAdapter,
  folder: 'domains',
  callbackDomains?: (domains_name?: string[]) => Promise<any>,
  ms = timeLoad,
  isLoad = false
) {
  try {
    const partMq: S3Types._mq_active = await adapter.getMqActive({ files: [folder] })

    const mqDomains: S3Types._mq_domains | undefined = partMq[folder]
    if (
      mqDomains?.updated &&
      (!process._repository?._mq_active?.[folder]?.updated ||
        mqDomains.updated !== process._repository._mq_active[folder]?.updated ||
        isLoad)
    ) {
      if (mqDomains?.domains) {
        const domains_name = Object.entries(mqDomains.domains).reduce(
          (acc: string[], [index, updated]) => {
            if (
              !process._repository?._mq_active?.[folder]?.domains?.[index] ||
              updated !== process._repository?._mq_active?.[folder]?.domains?.[index]
            ) {
              return [...acc, index]
            }
            return acc
          },
          []
        )

        try {
          logger.info(`Watch MQ active[${folder}] Updating domains =`, domains_name)
          const t = process.hrtime()
          await callbackDomains?.(domains_name)
          logger.info(
            `Watch MQ[${folder}] update completed in: ${getDurationInSeconds(t).toLocaleString()}s`
          )
        } catch (err2) {
          logger.error(`Watch MQ active[${folder}] default update error`, err2)
        }
      }
      if (process._repository?._mq_active) process._repository._mq_active[folder] = mqDomains
    }
  } catch (err: any) {
    logger.error(`Watch MQ[${folder}] failed`, err)
  }
  if (!isLoad) {
    setTimeout(() => watchMqDomain(adapter, folder, callbackDomains), ms)
  }
}
