import { UpdatedCart } from '../order/services'
import { toDictionary } from './utilities'

export const preCalcOrder = (items: SfTypes.Item[]): UpdatedCart => {
  const items_amount = items.reduce(
    (total, item) => (total += (item.price ?? 0) * (item.quantity ?? 0)),
    0
  )
  return {
    amount: items_amount,
    items_amount,
    // discount_amount: 0,
    // shipping_amount: 0,
    items: toDictionary(
      items,
      (item) => item.id,
      (item) => item.price ?? 0
    )
  }
}
