import { priceChangedVariantItemsKey } from '@libs/client/helpers'
import { useGlobalState } from '@libs/client/hooks'
import { PriceChangedVariantItems } from '@libs/common'
import { useRouter } from 'next/router'

export const usePriceChangedItems = () => {
  const router = useRouter()
  const slug = router.query.subpath?.toString()
  const [changedItems, setChangedItems] = useGlobalState<PriceChangedVariantItems>(
    priceChangedVariantItemsKey(slug)
  )
  return [changedItems, setChangedItems] as const
}
