import {
  genSid,
  localBuyNowOrderKey,
  localIsBuyNowKey,
  localOrderIdKey,
  localOrderKey,
  setGlobalState
} from '@libs/client'
import { AbandonedCart, CartItem } from '@libs/client/order/services'
import { LOCAL_ORDER_STATUS } from '@ui/contexts'
import { getCountStatus } from '@ui/services'
import { v4 as uuid } from 'uuid'
import { setLocalObject } from './local'
import { fireNextState, SubscribeStateKey } from './stateSubscriber'

export function countItems(orderVariants: Types.OrderItem[]) {
  return orderVariants.reduce((count, ov) => count + (ov.quantity ?? 0), 0)
}

export function newOrderToken() {
  return `${uuid()}-${Date.now()}`
}

export function resetCartBySlug(slug?: string) {
  setGlobalState({ [localBuyNowOrderKey(slug)]: null })
  setGlobalState({ [localOrderKey(slug)]: null })
  setGlobalState({ [localOrderIdKey(slug)]: null })
  setGlobalState({ [localIsBuyNowKey(slug)]: false })
}

export function cartMapping(
  cart: AbandonedCart,
  variants: S3Types.page_variants_items
): Types.Order {
  return {
    ...cart,
    items: variantMapping(variants, cart?.items ?? [])
  }
}

const variantMapping = (
  variants: S3Types.page_variants_items,
  orderItems: CartItem[]
): Types.OrderItem[] => {
  return orderItems.flatMap((item) => {
    if (!item.id || !item.variant_id) return []
    const match = variants[item.variant_id]
    if (!match) return []
    return {
      id: item.id,
      variant_id: item.variant_id,
      variant_data: item.variant_data,
      quantity: item.quantity,
      imageUrl: match.logo,
      compare_price: match.compare_price,
      price: match.default_price,
      properties: match.properties
    }
  })
}

export function orderShopHelpMapping(order: Types.OrderShopHelp): Types.Order {
  return {
    amount: order.payment?.total_amount ?? 0,
    billing: order.billing,
    cardInfo: {
      card_first4: order.payment?.card_info?.first4digits,
      card_last4: order.payment?.card_info?.last4digits,
      card_brand: order.payment?.card_info?.brand
    },
    code: order.name,
    created: order.created,
    discount_amount: order.payment?.discount_price,
    discount_code: order.payment?.discount_code,
    discount_value: `${order.payment?.discount_percent ?? ''}`,
    tax_value: order.payment?.tax_amount,
    id: order.id,
    items_amount: order.payment?.subtotal,
    shipping: order.shipping,
    shipping_amount: order.payment?.shipping_price,
    shipping_code: order.payment?.shipping_name,
    transactionProvider: order.payment?.payment_type === 'PAYPAL' ? 'PAYPAL' : 'STRIPE',
    items: order.order_lines?.map((item) => ({
      id: genSid(),
      compare_price: item.compared_price,
      price: item.unit_price,
      imageUrl: item.variant_image,
      variant_id: item.variant_id,
      quantity: item.quantity,
      properties: item.variant_properties?.reduce((total, property) => {
        total[property.name ?? ''] = property.value ?? ''
        return total
      }, {} as Record<string, string>)
    }))
  }
}

export function setLocalOrderStatus(order: Types.OrderShopHelp) {
  const { tracking_infos } = order
  if (!tracking_infos) return
  const countStatus = getCountStatus(tracking_infos!)
  const orderStatus = {
    allCancelled: countStatus.canceled === tracking_infos?.length,
    isDelivered: countStatus.delivered > 0
  }
  setLocalObject(LOCAL_ORDER_STATUS, orderStatus)
  fireNextState(SubscribeStateKey.ORDER_STATUS, orderStatus)
}

// export function isFulfilledByStep(tracking: Types.Tracking, step: Types.FulfillStatus) {
//   if (tracking.trackingStatus === 'DELIVERED') {
//     return true
//   }
//   if (tracking.trackingStatus === 'SHIPPED') {
//     return step !== 'DELIVERED'
//   }
//   if (tracking.trackingStatus === 'PROCESSING') {
//     return step !== 'DELIVERED' && step !== 'SHIPPED'
//   }
//   if (tracking.trackingStatus === 'CONFIRMED') {
//     return step !== 'DELIVERED' && step !== 'SHIPPED' && step !== 'PROCESSING'
//   }
//   return false
// }

// export function getBestTrackings(trackings: Types.Tracking[]) {
//   const hasDelivered = trackings.some(
//     (tracking: Types.Tracking) => tracking.trackingStatus === 'DELIVERED'
//   )
//   if (hasDelivered) {
//     return trackings.find((tracking: Types.Tracking) => tracking.trackingStatus === 'DELIVERED')
//   }

//   const hasShipped = trackings.some(
//     (tracking: Types.Tracking) => tracking.trackingStatus === 'SHIPPED'
//   )
//   if (hasShipped) {
//     return trackings.find((tracking: Types.Tracking) => tracking.trackingStatus === 'SHIPPED')
//   }

//   const hasProcessing = trackings.some(
//     (tracking: Types.Tracking) => tracking.trackingStatus === 'PROCESSING'
//   )
//   if (hasProcessing) {
//     return trackings.find((tracking: Types.Tracking) => tracking.trackingStatus === 'PROCESSING')
//   }
//   const hasConfirmed = trackings.some(
//     (tracking: Types.Tracking) => tracking.trackingStatus === 'CONFIRMED'
//   )
//   if (hasConfirmed) {
//     return trackings.find((tracking: Types.Tracking) => tracking.trackingStatus === 'CONFIRMED')
//   }

//   return undefined
// }
