import { getCookie } from '@ui/helpers/getCookie'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export function usePageVersionCookie() {
  const [pversion, setPversion] = useState<string>('')
  const router = useRouter()
  const subpath = router?.query?.subpath?.toString()
  useEffect(() => {
    const key = `pversion${subpath ? `_${subpath}` : ''}`
    setPversion(getCookie(key) || '')
  }, [])
  return pversion
}
