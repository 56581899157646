import { TrackingInfo } from '@ui/types/interfaces'

export const getActiveDo = (lineItem: TrackingInfo, tracking_infos: TrackingInfo[]) => {
  const listDoByLineItemId =
    tracking_infos?.filter((o) => o.line_item_id == lineItem.line_item_id) ?? []

  //group do by odo_id (get unq odo_id ) => filter cancel => sort by create => get active DO with max date => flat
  const listUnqOdoId = [...new Set(listDoByLineItemId?.map((o) => o.odo_id ?? ''))]

  // [[do1a, do1b], [do2a, do2b] , [do3a, do3b, do3c]]
  const filterDoChunks = listUnqOdoId.map((odoId) =>
    listDoByLineItemId.filter((o) => o.odo_id == odoId && o.do_status != 'CANCELLED')
  )

  const activeDos = filterDoChunks
    .flatMap((doChunk) => {
      const dos = [...doChunk].sort((a, b) => (b.created ?? 0) - (a.created ?? 0))
      if (dos && dos[0]) {
        return {
          doId: dos[0].po_item_id as string,
          doStatus: dos[0].do_status as string,
          odoId: dos[0].odo_id as string,
          doCreated: dos[0].created as number
        }
      }
      return {}
    })
    .filter((o) => o?.doId)

  return activeDos
}

export const isDisplayGroupByLineItem = (items?: Types.TrackingInfo[]) => {
  if (!items) return false
  return items?.every((o) => o.line_item_id)
}
