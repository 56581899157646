import S3 from 'aws-sdk/clients/s3'
import objectPath from 'object-path'
import { logger } from '.'
import { toJson } from './common'

let _client: S3 | null = null
let _server: S3 | null = null
const _watchers: { [key: string]: number } = {}

const s3Client = () => {
  if (!_client) {
    _client = new S3({
      accessKeyId: process.env.AWS_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.AWS_S3_SECRET_ACCESS_KEY,
      region: process.env.AWS_S3_REGION
    })
  }
  return _client
}

const s3Server = () => {
  if (!_server) {
    _server = new S3({
      accessKeyId: process.env.AWS_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.AWS_S3_SECRET_ACCESS_KEY,
      region: process.env.AWS_S3_THUMBS_REGION
    })
  }
  return _server
}

export const getS3Value = async (key: string, type = 'json') => {
  logger.debug('get S3 file: ', key)
  const params = {
    Bucket: process.env.AWS_S3_BUCKET || '',
    Key: key
  }
  try {
    const data: S3.GetObjectOutput = await s3Client()?.getObject(params).promise()
    switch (type) {
      case 'json': {
        const json = toJson((data.Body as string | Uint8Array) || '')
        return json
      }
      default: {
        return data.Body
      }
    }
  } catch (err: any) {
    logger.error(`cannot get S3 file ${key}`, err)
    return null
  }
}

export const getS3Folder = async (key: string, type = 'json') => {
  // logger.info('get S3 folder: ', key, type)
  try {
    const allKeys: string[] = []
    let data: any = null
    do {
      const params = {
        Bucket: process.env.AWS_S3_BUCKET || '',
        Prefix: key,
        ...(data?.NextContinuationToken ? { ContinuationToken: data.NextContinuationToken } : {})
      }
      data = await s3Client().listObjectsV2(params).promise()
      allKeys.push(
        ...data.Contents?.map((each: any) => each.Key).filter((path: string) => path.endsWith(type))
      )
    } while (data.IsTruncated)
    return allKeys
  } catch (err: any) {
    logger.error(`cannot get S3 folder ${key}`, err)
    return null
  }
}

/** Generate S3Thumb when call /api/thumb */
export const setS3Thumb = async (key: string, file: any, contentType?: string) => {
  logger.info('---> set S3 file: ', key)
  const params = {
    Bucket: process.env.AWS_S3_THUMBS || '',
    Key: key,
    Body: file,
    ContentType: contentType || file.contentType || file.mimetype || 'application/octet-stream'
  }
  try {
    const data: S3.GetObjectOutput = await s3Server()?.upload(params).promise()
    return data
  } catch (err: any) {
    logger.error(`cannot set S3 file`, key, err)
    return null
  }
}

export const watchS3 = async (files: string[], updated: () => Promise<void>, ms = 1000) => {
  const updatedValues = await Promise.all(files.map((file) => watchS3File(file)))
  const updatedIndex = updatedValues.findIndex((v) => !!v && v > 0)
  if (updatedIndex >= 0) {
    const start = new Date().valueOf()
    try {
      await updated()
      for (let i = updatedValues.length - 1; i >= 0; i--) {
        if (typeof updatedValues[i] === 'number') {
          _watchers[files[i]] = <number>updatedValues[i]
        }
      }
      console.log(
        `WatchS3[${files[updatedIndex]}] Update completed in ${new Date().valueOf() - start} (ms)`
      )
    } catch (err: any) {
      console.error(`WatchS3[${files[updatedIndex]}] Update error`, err)
    }
  }

  setTimeout(() => watchS3(files, updated, ms), ms)
}

const watchS3File = async (file: string): Promise<number | undefined> => {
  const currentUpdated = _watchers[file] || 0
  const mqValue = await getS3Value(file, 'json')
  if (
    mqValue === null ||
    typeof mqValue === 'undefined' ||
    mqValue.updated === null ||
    typeof mqValue.updated !== 'number'
  ) {
    console.warn(`WatchS3[${file}] error while getting data from MQ`)
  } else {
    if (currentUpdated < mqValue.updated) {
      console.log(`WatchS3[${file}] Updating...`)
      if (currentUpdated < mqValue.updated) {
        return mqValue.updated
      }
    }
  }
  return undefined
}

export const loadDataByFolder = async <T>(dataKey: string, filters?: string[]) => {
  const paths = await getS3Folder(dataKey)
  const data: T = {} as T
  await Promise.allSettled(
    paths && paths?.map
      ? paths.map(async (path) => {
          if (
            (!filters ||
              (filters &&
                filters.some(
                  (filter_id) =>
                    path.indexOf(`${dataKey}/${filter_id}.json`) > -1 ||
                    path.indexOf(`${dataKey}/${filter_id}/`) > -1
                ))) &&
            path.endsWith('.json') &&
            // !path.endsWith('promotions.json') &&
            !path.endsWith('default.json')
          ) {
            try {
              const temp = await getS3Value(path)
              const subPath = (path.startsWith(`${dataKey}/`)
                ? path.replace(`${dataKey}/`, '')
                : path
              ).replace('.json', '')
              objectPath.set(data, subPath.split('/'), temp)
            } catch (err: any) {
              logger.error(err)
            }
          }
        })
      : []
  )
  return data
}
