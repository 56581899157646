import axios, { AxiosInstance } from 'axios'
import { getLocalObject } from '@ui/helpers'
const pendingRequest = new Set()

export default function useAxios<ResponseType>(
  baseUrl = '',
  header = {},
  resultHandler?: {
    responseHandler?: (response: ResponseType) => ResponseType
  }
) {
  const instance: AxiosInstance = axios.create({
    baseURL: baseUrl || '',
    headers: {
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      ...header
    },
    withCredentials: true
    // timeout: 1800000
  })

  const instancePreview: AxiosInstance = axios.create({
    baseURL: baseUrl || '',
    headers: {
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    withCredentials: true
    // timeout: 1800000
  })

  // TODO: Merge with interceptor of createAuthRefresh
  instance.interceptors.response.use(
    // @ts-ignore
    (response: AxiosResponse) => {
      const { data } = response
      if (Array.isArray(data) && resultHandler?.responseHandler) {
        return data.map((item) => {
          if (resultHandler.responseHandler) return resultHandler.responseHandler(item)
        })
      }
      return resultHandler?.responseHandler ? resultHandler.responseHandler(data) : data
    },
    (err) => {
      if (err?.response?.config?.url) {
        pendingRequest.delete(err?.response?.config?.url)
      }
    }
  )

  // Cancel duplicated Request
  instance.interceptors.request.use((config: any) => {
    const configTmp = { ...config }
    const token = getLocalObject('AUTH_LOGIN')?.access_token
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    if (pendingRequest.has(config.url)) {
      configTmp.cancelToken = new axios.CancelToken((cancel) => cancel('Cancel repeated request'))
    } else {
      pendingRequest.add(config.url)
    }

    setTimeout(() => pendingRequest.delete(config.url), 3500)
    return configTmp
  })

  // TODO: Merge with interceptor of createAuthRefresh
  instancePreview.interceptors.response.use(
    // @ts-ignore
    (response: AxiosResponse) => {
      const { data } = response
      if (Array.isArray(data) && resultHandler?.responseHandler) {
        return data.map((item) => {
          if (resultHandler.responseHandler) return resultHandler.responseHandler(item)
        })
      }
      return resultHandler?.responseHandler ? resultHandler.responseHandler(data) : data
    },
    (err) => {
      if (err?.response?.config?.url) {
        pendingRequest.delete(err?.response?.config?.url)
      }
    }
  )

  // Cancel duplicated Request
  instancePreview.interceptors.request.use((config: any) => {
    const configTmp = { ...config }
    if (pendingRequest.has(config.url)) {
      configTmp.cancelToken = new axios.CancelToken((cancel) => cancel('Cancel repeated request'))
    } else {
      pendingRequest.add(config.url)
    }

    setTimeout(() => pendingRequest.delete(config.url), 3500)
    return configTmp
  })

  return {
    instance,
    instancePreview
  }
}
