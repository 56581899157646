import { useUpdateEffect } from '@ui/hooks'
import { useEffect, useRef, useState } from 'react'

type CountdownProps = {
  time?: number
  template?: number
  className?: string
  callBack?: () => void
  isPause?: boolean
}

function getHour(currentTime: number) {
  const hours = Math.floor(currentTime / 3600)
  return hours < 10 ? `0${hours}` : hours
}

function getMinute(currentTime: number) {
  const minutes = Math.floor((currentTime % 3600) / 60)
  return minutes < 10 ? `0${minutes}` : minutes
}

function getSecond(currentTime: number) {
  const seconds = Math.floor(currentTime % 60)
  return seconds < 10 ? `0${seconds}` : seconds
}

function Countdown({
  time = 600,
  template = 1,
  className,
  callBack,
  isPause = false
}: CountdownProps) {
  const [currentTime, setCurrentTime] = useState(time)
  const [hour, setHour] = useState(getHour(time))
  const [minute, setMinute] = useState(getMinute(time))
  const [second, setSecond] = useState(getSecond(time))
  const intervalRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    intervalRef.current = setTimeout(() => {
      if (currentTime >= 1 && !isPause) {
        const tmp = Math.floor(currentTime) - 1
        setHour(getHour(tmp))
        setMinute(getMinute(tmp))
        setSecond(getSecond(tmp))
        setCurrentTime(tmp)
        if (tmp === 0 && callBack) callBack()
      } else {
        intervalRef.current && clearInterval(intervalRef.current)
      }
    }, 1000)
  }, [currentTime, isPause])

  useUpdateEffect(() => {
    if (!isPause) {
      setCurrentTime(currentTime + 0.1)
    } else {
      intervalRef.current && clearInterval(intervalRef.current)
    }
  }, [isPause])

  useEffect(() => {
    return () => {
      intervalRef.current && clearInterval(intervalRef.current)
    }
  }, [])

  return (
    <span className={`countdown ${className}`}>
      {template === 1 && (
        <span>
          {hour}h {minute}m {second}s
        </span>
      )}
      {template === 2 && (
        <span>
          {minute}:{second}
        </span>
      )}
    </span>
  )
}

export default Countdown
