export const getCookie = (name: string) => {
  if (process.browser) {
    const value = `; ${document.cookie}`
    const parts: any = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
  }
}
export const setCookie = (cName: string, cValue: string, expDays: number, isSameSite = false) => {
  if (process.browser) {
    const date = new Date()
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000)
    const expires = expDays ? 'expires=' + date.toUTCString() : ''
    document.cookie =
      cName + '=' + cValue + '; ' + expires + '; path=/' + `${isSameSite ? '; SameSite=Lax' : ''}`
  }
}
