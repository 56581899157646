import Script from 'next/script'
import React from 'react'

interface IAxonScript {
  axonIds: string[]
}

export function AxonScript({ axonIds }: IAxonScript) {
  return (
    <React.Fragment>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
             var AXON_EVENT_KEY="${axonIds[0]}";
              !function(e,r){var t=["https://s.axon.ai/pixel.js","https://c.albss.com/p/l/loader.iife.js"];if(!e.axon){var a=e.axon=function(){a.performOperation?a.performOperation.apply(a,arguments):a.operationQueue.push(arguments)};a.operationQueue=[],a.ts=Date.now(),a.eventKey=AXON_EVENT_KEY;for(var n=r.getElementsByTagName("script")[0],o=0;o<t.length;o++){var i=r.createElement("script");i.async=!0,i.src=t[o],n.parentNode.insertBefore(i,n)}}}(window,document);

              axon("init");
          `
        }}
      />
    </React.Fragment>
  )
}
